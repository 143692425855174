import { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Navigate } from 'react-router';

import { RouteNames } from './routes-enums';

interface Props {
  children: JSX.Element;
}

const PublicRoutes: FC<Props> = ({ children }) => {
  const token = JSON.parse(localStorage.getItem('userToken') || '{}');

  return !token.token ? children : <Navigate to={RouteNames.HOME} />;
};

export default PublicRoutes;
