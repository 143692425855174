import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Burger, Group, Text } from '@mantine/core';

import styles from './LayoutNavbarBurger.module.scss';

interface ILayoutNavbarBurger {
  opened: boolean;
  onClick?: () => void;
}

const LayoutNavbarBurger: FC<ILayoutNavbarBurger> = ({ opened, onClick }) => {
  const title = opened ? 'Close navigation' : 'Open navigation';
  const { t } = useTranslation();

  return (
    <Group className={styles.root} noWrap spacing={10} onClick={onClick}>
      <Burger className={styles.icon} color={'#BFCEDB'} opened={opened} title={title} size={15} />
      {opened && (
        <Text className={styles.text} lineClamp={1}>
          {t('Layout.Roll up')}
        </Text>
      )}
    </Group>
  );
};

export default LayoutNavbarBurger;
