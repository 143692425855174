import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  AddUserData,
  addUserFetch,
  confirmEmailFetch,
  DeleteUserData,
  deleteUserFetch,
  EditUserData,
  editUserFetch,
  getUserFetch,
  GetUsersData,
  getUsersFetch,
  LogInToUserEmulationData,
  logInToUserEmulationFetch,
  logOutUserEmulationFetch,
  UpdateStatusUserData,
  updateStatusUserFetch,
} from '../../api/usersApi';

export const getUsers = createAsyncThunk('users/getUsers', async (arg: GetUsersData, thunkAPI) => {
  try {
    const response = await getUsersFetch(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (arg: DeleteUserData, thunkAPI) => {
    try {
      const response = await deleteUserFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const updateStatusUser = createAsyncThunk(
  'users/updateStatusUser',
  async (arg: UpdateStatusUserData, thunkAPI) => {
    try {
      const response = await updateStatusUserFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const editUser = createAsyncThunk('users/editUser', async (arg: EditUserData, thunkAPI) => {
  try {
    const response = await editUserFetch(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const logInToUserEmulation = createAsyncThunk(
  'users/logInToUserEmulation',
  async (arg: LogInToUserEmulationData, thunkAPI) => {
    try {
      const response = await logInToUserEmulationFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const logOutUserEmulation = createAsyncThunk(
  'users/logOutUserEmulation',
  async (arg, thunkAPI) => {
    try {
      const response = await logOutUserEmulationFetch();
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const getUser = createAsyncThunk('users/getUser', async (arg: { id: number }, thunkAPI) => {
  try {
    const response = await getUserFetch(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const addUser = createAsyncThunk('users/addUser', async (arg: AddUserData, thunkAPI) => {
  try {
    const response = await addUserFetch(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const confirmEmail = createAsyncThunk(
  'users/confirmEmail',
  async (arg: { id: number }, thunkAPI) => {
    try {
      const response = await confirmEmailFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
