import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Tabs } from '@mantine/core';
import { getSubscriptionView } from 'src/store/action-creators/tariff';
import Layout from 'src/ui/Layout/Layout';

import { getProvidersAuth } from '../../../store/action-creators/providersAuth';
import { useAppDispatch } from '../../../store/hooks';
import ContentContainer from '../../../ui/ContentContainer/ContentContainer';

import ProfileTariff from './ProfileTariff/ProfileTariff';
import ProfileChangeLanguage from './ProfileChangeLanguage';
import ProfileChangePassword from './ProfileChangePassword';
import ProfileInfo from './ProfileInfo';
import ProfileSettings from './ProfileSettings';

interface LocationState {
  state: {
    tabName?: string;
  };
}

const Profile: FC = () => {
  const location = useLocation();
  const { state } = location as LocationState;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string | null>(state?.tabName || 'profile');
  const dispatch = useAppDispatch();

  useEffect(() => {
    setActiveTab(state?.tabName || 'profile');
  }, [state]);

  useEffect(() => {
    dispatch(getSubscriptionView());
  }, [])

  useEffect(() => {
    dispatch(getProvidersAuth());
  }, []);

  return (
    <Layout title={t('ProfilePage.Profile.Profile settings')}>
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="profile">{t('ProfilePage.Profile.Profile')}</Tabs.Tab>
          <Tabs.Tab value="tariff">{t('ProfilePage.Profile.Profile tariff')}</Tabs.Tab>
          <Tabs.Tab value="password">{t('ProfilePage.Change Password.Change Password')}</Tabs.Tab>
          <Tabs.Tab value="settings">{t('ProfilePage.Profile.Profile settings')}</Tabs.Tab>
          <Tabs.Tab value="language">
            {t('ProfilePage.Interface language.Interface language')}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="profile" pt="xs">
          <ContentContainer>
            <ProfileInfo />
          </ContentContainer>
        </Tabs.Panel>

        <Tabs.Panel value="tariff" pt="xs">
          <ContentContainer>
            <ProfileTariff />
          </ContentContainer>
        </Tabs.Panel>

        <Tabs.Panel value="password" pt="xs">
          <ContentContainer>
            <ProfileChangePassword />
          </ContentContainer>
        </Tabs.Panel>

        <Tabs.Panel value="settings" pt="xs">
          <ContentContainer>
            <ProfileSettings />
          </ContentContainer>
        </Tabs.Panel>

        <Tabs.Panel value="language" pt="xs">
          <ContentContainer>
            <ProfileChangeLanguage />
          </ContentContainer>
        </Tabs.Panel>
      </Tabs>
    </Layout>
  );
};

export default Profile;
