import axiosInstance from "./axiosInstance";

export interface AddStatusData {
    order: number;
    title: string;
}

export interface ChangeOrderData {
    status_id: number;
}

export interface DeleteStatusData {
    status_id: number;
}

export interface UpdateStatusData {
    status_id: number;
    order: number;
    title: string;
}

export interface AddThemeData {
    title: string;
}

export interface DeleteThemeData {
    theme_id: number;
}

export interface UpdateThemeData {
    theme_id: number;
    title: string;
}

export interface AddTicketData {
    description: string;
    file: string;
    theme_id: number;
}

export interface AddTicketDataManager {
    description: string;
    file: string;
    theme_id: number;
    user_id: number;
}

export interface CloseTicketsData {
    selected_tickets: Array<number>;
}

export interface DeleteTicketsData {
    selected_tickets: Array<number>;
}

export interface ViewTicketsData {
    ticket_id: number;
}

export interface ListTicketsData {
    manager_id?: number;
    user_id?: number;
    status_id?: number;
    ticket_rating?: number;
    from_date?: string | null;
    to_date?: string | null;
    page?: number;
    items_per_page?: number;
    sort_by?: string;
    sort_order?: string | 'desc';
}

export interface AddCommentData {
    ticket_id: number;
    content: string;
}

export interface ChangeManagerData {
    ticket_id: number;
    manager_id: number;
}

export interface ChangeStatusData {
    ticket_id: number;
    status_id: number;
}

export interface DeleteCommentData {
    ticket_id: number;
    comment_id: number;
}

export const getStatuses = async () => {
    const response = await axiosInstance.get('/api/tickets/list-statuses');
    return response;
}

export const getThemes = async () => {
    const response = await axiosInstance.get('/api/tickets/list-themes');
    return response;
}

export const getTicketFields = async () => {
    const response = await axiosInstance.get('/api/tickets/list-ticket-table-fields');
    return response;
}

export const addStatus = async ({ order, title }: AddStatusData) => {
    const response = await axiosInstance.post('/api/tickets/status/add-status', {
        order,
        title
    });
    return response;
}

export const changeOrder = async ({ status_id }: ChangeOrderData) => {
    const response = await axiosInstance.patch(`/api/tickets/status/${status_id}/change-order`);
    return response;
}

export const deleteStatus = async ({ status_id }: DeleteStatusData) => {
    const response = await axiosInstance.delete(`/api/tickets/status/${status_id}/delete-status`);
    return response;
}

export const updateStatus = async ({ status_id, order, title }: UpdateStatusData) => {
    const response = await axiosInstance.put(`/api/tickets/status/${status_id}/update-status`, {
        order,
        title
    });
    return response;
}

export const addTheme = async ({ title }: AddThemeData) => {
    const response = await axiosInstance.post(`/api/tickets/theme/add-theme`, {
        title
    });
    return response;
}

export const deleteTheme = async ({ theme_id }: DeleteThemeData) => {
    const response = await axiosInstance.delete(`/api/tickets/theme/${theme_id}/delete-theme`);
    return response;
}

export const updateTheme = async ({ theme_id, title }: UpdateThemeData) => {
    const response = await axiosInstance.put(`/api/tickets/theme/${theme_id}/update-theme`, {
        title
    });
    return response;
}

export const addTicket = async ({ description, file, theme_id }: AddTicketData) => {
    const response = await axiosInstance.post('/api/tickets/ticket/add-ticket', {
        description,
        file,
        theme_id,
    })
    return response;
}

export const addTicketManager = async ({ description, file, theme_id, user_id }: AddTicketDataManager) => {
    const response = await axiosInstance.post('/api/tickets/ticket/add-ticket-by-manager', {
        description,
        file,
        theme_id,
        user_id,
    })
    return response;
}

export const closeTickets = async ({ selected_tickets }: CloseTicketsData) => {
    const response = await axiosInstance.post('/api/tickets/ticket/close-tickets', {
        selected_tickets
    })
    return response;
}

export const deleteTickets = async ({ selected_tickets }: DeleteTicketsData) => {
    const response = await axiosInstance.post('/api/tickets/ticket/delete-tickets', {
        selected_tickets
    })
    return response;
}

export const viewTickets = async ({ ticket_id }: ViewTicketsData) => {
    const response = await axiosInstance.get(`/api/tickets/ticket/detailed-view/${ticket_id}`)
    return response;
}

export const getListTickets = async (data?: ListTicketsData) => {
    const response = await axiosInstance.get('/api/tickets/ticket/list-tickets/',{
        params: data
    });
    return response;
}

export const addComment = async ({ ticket_id, content }: AddCommentData) => {
    const response = await axiosInstance.post(`/api/tickets/ticket/${ticket_id}/add-comment`, {
        content
    })
    return response;
}

export const changeManager = async ({ ticket_id, manager_id }: ChangeManagerData) => {
    const response = await axiosInstance.patch(`/api/tickets/ticket/${ticket_id}/change-manager`, {
        manager_id
    })
    return response;
}

export const changeStatus = async ({ ticket_id, status_id }: ChangeStatusData) => {
    const response = await axiosInstance.patch(`/api/tickets/ticket/${ticket_id}/change-status`, {
        status_id
    })
    return response;
}

export const deleteCommentFetch = async ({ ticket_id, comment_id }: DeleteCommentData) => {
    const response = await axiosInstance.delete(`/api/tickets/ticket/${ticket_id}/delete-comment/${comment_id}`)
    return response;
}