import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Navbar, ScrollArea } from '@mantine/core';
import { RolesEnum } from 'src/constants/roles/roles';
import { RouteNames } from 'src/routes/routes-enums';
import { useAppSelector } from 'src/store/hooks';
import {
  Adjustments,
  ApiApp,
  Book,
  BrandTelegram,
  ChartPie,
  GridDots,
  Help,
  Mail,
  News,
  UserCheck,
  Users,
} from 'tabler-icons-react';

import LayoutNavbarBurger from './components/LayoutNavbarBurger/LayoutNavbarBurger';
import LayoutNavbarButton from './components/LayoutNavbarButton/LayoutNavbarButton';
import LayoutNavbarLogo from './components/LayoutNavbarLogo/LayoutNavbarLogo';

import styles from './LayoutNavbar.module.scss';

const LayoutNavbar: FC = () => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(true);
  const { user } = useAppSelector((state) => state.auth);

  const navigation = {
    main: [
      {
        href: RouteNames.HOME,
        title: t('Layout.Dashboard'),
        icon: <GridDots size={24} />,
      },
      {
        href: RouteNames.CLIENTS,
        title: t('Layout.Clients'),
        icon: <UserCheck size={24} />,
      },
      {
        href: RouteNames.SEGMENTS,
        title: t('Layout.Segments'),
        icon: <ChartPie size={24} />,
      },
      {
        href: RouteNames.TELEGRAM,
        title: t('Layout.Telegram'),
        icon: <BrandTelegram size={24} />,
      },
      {
        href: RouteNames.SUPPORT,
        title: t('Layout.Support'),
        icon: <Help size={24} />,
      },
      {
        href: RouteNames.INTEGRATIONS,
        title: t('Layout.Integrations'),
        icon: <ApiApp size={24} />,
      },
      {
        href: RouteNames.HELP,
        title: t('Layout.Help'),
        icon: <Book size={24} />,
      },
    ],
    admin: [
      {
        href: RouteNames.ADMIN_USERS,
        title: t('Layout.Users'),
        icon: <Users size={24} />,
      },
      {
        href: RouteNames.ADMIN_CONTENT,
        title: t('Layout.Content'),
        icon: <News size={24} />,
      },
      {
        href: RouteNames.ADMIN_NOTIFICATIONS,
        title: t('Layout.Notifications'),
        icon: <Mail size={24} />,
      },
      {
        href: RouteNames.ADMIN_TICKETS,
        title: t('Layout.Tickets'),
        icon: <Help size={24} />,
      },
      {
        href: RouteNames.ADMIN_SETTINGS,
        title: t('Layout.Settings'),
        icon: <Adjustments size={24} />,
      },
    ],
  };

  return (
    <Navbar
      className={styles.navbar}
      width={{ base: opened ? 220 : 72 }}
      height={'100vh'}
      pl={24}
      py={30}
      style={{ transition: 'width .25s ease-in-out' }}
    >
      <Navbar.Section pb={12}>
        <LayoutNavbarLogo opened={opened} />
        <LayoutNavbarBurger opened={opened} onClick={() => setOpened(!opened)} />
      </Navbar.Section>

      <Navbar.Section grow component={ScrollArea}>
        {navigation.main.map((item) => (
          <LayoutNavbarButton
            key={item.href}
            opened={opened}
            label={item.title}
            icon={item.icon}
            href={item.href}
          />
        ))}

        {(user?.role === RolesEnum.SUPERUSER || user?.role === RolesEnum.ADMIN) && (
          <>
            <Divider my={10} mr={24} color="#bfcedb" />
            {navigation.admin.map((item) => (
              <LayoutNavbarButton
                key={item.href}
                opened={opened}
                label={item.title}
                icon={item.icon}
                href={item.href}
              />
            ))}
          </>
        )}
      </Navbar.Section>
    </Navbar>
  );
};

export default LayoutNavbar;
