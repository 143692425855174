import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { notificationsApi } from 'src/api/notificationsApi';

import { CreateNotificationData, NotificationsState } from './types';

export const getNotifications = createAsyncThunk<any, void, { rejectValue: string }>(
  'notifications/getNotifications',
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await notificationsApi.getNotifications();
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const readNotifications = createAsyncThunk<any, void, { rejectValue: string }>(
  'notifications/readNotifications',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const {
        notifications: { notifications },
      } = getState() as { notifications: NotificationsState };

      const { data } = await notificationsApi.readNotifications({
        user_notification_ids: notifications?.map((item) => item.id) || [],
      });
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const deleteNotifications = createAsyncThunk<any, void, { rejectValue: string }>(
  'notifications/deleteNotifications',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const {
        notifications: { notifications },
      } = getState() as { notifications: NotificationsState };

      const { data } = await notificationsApi.deleteNotifications({
        user_notification_ids: notifications?.map((item) => item.id) || [],
      });
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const readNotificationById = createAsyncThunk<any, number, { rejectValue: string }>(
  'notifications/readNotificationById',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await notificationsApi.readNotificationById(id);
      return { id, ...data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const deleteNotificationById = createAsyncThunk<any, number, { rejectValue: string }>(
  'notifications/deleteNotificationById',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await notificationsApi.deleteNotificationById(id);
      return { id, ...data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const createNotification = createAsyncThunk<
  any,
  CreateNotificationData,
  { rejectValue: string }
>('notifications/createNotification', async (formData, { rejectWithValue }) => {
  try {
    const { data } = await notificationsApi.createNotification({ ...formData, type: 'text' });
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getUsersForNotifications = createAsyncThunk<any, void, { rejectValue: string }>(
  'notifications/getUsersForNotifications',
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await notificationsApi.getUsersForNotifications();
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);
