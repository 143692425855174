import { providers } from '../constants/providers/providers';
import { IProviderItem } from '../store/entities/auth/types';
import { IUser } from '../store/types/IUser';
import { IProviders } from '../types/IProviders';

export const providerExist = (provState: IProviderItem[]) => {
  let prov = providers;
  let arr = [];
  for (let i = 0; i < prov.length; i++) {
    for (let j = 0; j < provState.length; j++) {
      if (providers[i].providerName === provState[j].provider_name) {
        arr.push({
          providerName: providers[i].providerName,
          logo: providers[i].logo,
          link: provState[j].oauth_location,
        });
      }
    }
  }
  return arr;
};

export const getProvidersProfile = (
  profile: IUser,
  existProviders: IProviders[],
  isConnect: boolean = true
) => {
  let arr: IProviders[] = [];
  existProviders.map((prov) => {
    let str = prov.providerName + '_user_data';
    if (profile.hasOwnProperty(str)) {
      // @ts-ignore
      if (isConnect && profile[str]) arr.push(prov);
      // @ts-ignore
      else if (!isConnect && !profile[str]) arr.push(prov);
    }
  });

  return arr;
};
