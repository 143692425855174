import {
  CreateNoticeData,
  GetNoticesByUrlData,
  GetNoticesParams,
  SetNoticeActivityData,
  UpdateNoticeData,
} from 'src/store/entities/notices/types';

import axiosInstance from './axiosInstance';

export const noticesApi = {
  getNoticesTypes() {
    return axiosInstance.request({
      method: 'get',
      url: 'api/notices/type_notice/list-by-get/',
    });
  },

  getNotices({ page = 1, items_per_page = 10 }: GetNoticesParams) {
    return axiosInstance.request({
      method: 'get',
      url: 'api/notices/notice/list-by-get/',
      params: {
        page,
        items_per_page,
      },
    });
  },

  createNotice(data: CreateNoticeData) {
    return axiosInstance.request({
      method: 'post',
      url: 'api/notices/notice/add-notice/',
      data,
    });
  },

  updateNotice(data: UpdateNoticeData) {
    return axiosInstance.request({
      method: 'post',
      url: 'api/notices/notice/update-notice/',
      data,
    });
  },

  deleteNotice(id: number) {
    return axiosInstance.request({
      method: 'delete',
      url: `api/notices/notice/delete-notice/${id}/`,
    });
  },

  setNoticeActivity(data: SetNoticeActivityData) {
    return axiosInstance.request({
      method: 'patch',
      url: 'api/notices/notice/set-status/',
      data,
    });
  },

  getNoticesByUrl(data: GetNoticesByUrlData) {
    return axiosInstance.request({
      method: 'post',
      url: 'api/notices/notice/info/',
      data,
    });
  },
};
