import { createSlice } from '@reduxjs/toolkit';

import { changeLanguage, editProfile, uploadAvatar } from '../../action-creators/profile';
import { IProfileState } from '../../types/IProfile';

const initialState: IProfileState = {
  isLoading: null,
  isSuccess: null,
  isError: null,
  message: null,
  formError: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfileState: (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = null;
      state.message = null;
      state.formError = null;
    },
  },

  extraReducers: {
    // Загрузка аватарки
    [uploadAvatar.pending.type]: (state) => {
      state.isLoading = 'UPLOAD_AVATAR';
    },
    [uploadAvatar.fulfilled.type]: (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'UPLOAD_AVATAR';
      state.isError = null;
      state.message = action.payload?.message;
    },
    [uploadAvatar.rejected.type]: (state, action) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'UPLOAD_AVATAR';
      state.message = action.payload?.message;
    },
    //
    [editProfile.pending.type]: (state) => {
      state.isLoading = 'EDIT_PROFILE';
    },
    [editProfile.fulfilled.type]: (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'EDIT_PROFILE';
      state.isError = null;
      state.message = action.payload?.message;
    },
    [editProfile.rejected.type]: (state, action) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'EDIT_PROFILE';
      state.message = action.payload?.message;
      state.formError = action.payload?.form_error;
    },
    //
    [changeLanguage.pending.type]: (state) => {
      state.isLoading = 'CHANGE_LANGUAGE';
    },
    [changeLanguage.fulfilled.type]: (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'CHANGE_LANGUAGE';
      state.isError = null;
      state.message = action.payload?.message;
    },
    [changeLanguage.rejected.type]: (state, action) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'CHANGE_LANGUAGE';
      state.message = action.payload?.message;
    },
  },
});

export const { resetProfileState } = profileSlice.actions;
export default profileSlice.reducer;
