import axiosInstance from './axiosInstance';

export interface GetHelpCardsData {
  page: number;
  sortType: 'asc' | 'desc';
  tags: number | null;
  sortBy: 'id';
  itemsPerPage: number;
  toDate: Date | null;
  fromDate: string | null;
  isLiked: boolean | null;
  categories: number | null;
  createdBy: number[];
}

export interface GetHelpCategoriesData {
  page: number;
  sortType: 'asc' | 'desc';
  sortBy: 'id';
  itemsPerPage: number;
  name: string | null;
}

export interface GetHelpTagsData {
  page: number;
  sortType: 'asc' | 'desc';
  sortBy: 'id';
  itemsPerPage: number;
  name: string | null;
}

export const getHelpCardsFetch = async ({
  page,
  sortType,
  tags,
  sortBy,
  itemsPerPage,
  toDate,
  fromDate,
  isLiked,
  categories,
  createdBy
}: GetHelpCardsData) => {
  const response = await axiosInstance.get('/api/news/', {
    params: {
      page: page,
      sort_order: sortType,
      tags: tags,
      sort_by:sortBy,
      items_per_page: itemsPerPage,
      to_date: toDate,
      from_date: fromDate,
      is_liked: isLiked,
      categories: categories,
      created_by: createdBy
    },
  });
  return response;
};

export const getHelpCategoriesFetch = async ({
  page,
  sortType,
  sortBy,
  itemsPerPage,
  name
}: GetHelpCategoriesData) => {
  const response = await axiosInstance.get('/api/news/category/', {
    params: {
      page: page,
      sort_order: sortType,
      sort_by:sortBy,
      per_page: itemsPerPage,
      name: name
    },
  });
  return response;
};

export const getHelpTagsFetch = async ({
  page,
  sortType,
  sortBy,
  itemsPerPage,
  name
}: GetHelpTagsData) => {
  const response = await axiosInstance.get('/api/news/tags/', {
    params: {
      page: page,
      sort_order: sortType,
      sort_by:sortBy,
      per_page: itemsPerPage,
      tag_name: name
    },
  });
  return response;
};