import React, { lazy } from 'react';

const HomePage = lazy(() => import('../pages/userPages/Home/Home'));
const Clients = lazy(() => import('../pages/userPages/Clients/Clients'));
const Segments = lazy(() => import('../pages/userPages/Segments/Segments'));
const Telegram = lazy(() => import('../pages/userPages/Telegram/Telegram'));
const Support = lazy(() => import('../pages/userPages/Support/Support'));
const Integrations = lazy(() => import('../pages/userPages/Integrations/Integrations'));
const Help = lazy(() => import('../pages/userPages/Help/Help'));

const Users = lazy(() => import('../pages/adminPages/Users/Users'));
const UsersAnalytics = lazy(() => import('../pages/adminPages/Users/UsersAnalytics/UsersAnalytics'));
const Content = lazy(() => import('../pages/adminPages/Content/Content'));
const Notifications = lazy(() => import('../pages/adminPages/Notifications/Notifications'));
const Tickets = lazy(() => import('../pages/adminPages/Tickets/Tickets'));
const TicketDetail = lazy (() => import('../pages/adminPages/Tickets/TicketsTable/TicketsActions/TicketDetailModal/TicketDetail'));
const Analytics = lazy(() => import('../pages/adminPages/Tickets/TicketsControls/Analytics/Analytics'));
const Settings = lazy(() => import('../pages/adminPages/Settings/Settings'));

const ResetPassword = lazy(() => import('../pages/publicPages/ResetPassword/ResetPassword'));
const SignUp = lazy(() => import('../pages/publicPages/SignUp/SignUp'));
const ConfirmEmail = lazy(() => import('../pages/publicPages/ConfirmEmail/ConfirmEmail'));
const ConfirmPassword = lazy(() => import('../pages/publicPages/ConfirmPassword/ConfirmPassword'));
const NewPassword = lazy(() => import('../pages/publicPages/NewPassword/NewPassword'));
const NotFound = lazy(() => import('../pages/publicPages/NotFound/NotFound'));
const TariffJoin = lazy(() => import('src/pages/userPages/Profile/ProfileTariff/TariffJoin'));


import ProviderCallback from '../pages/publicPages/ProviderCallback/ProviderCallback';
import SignIn from '../pages/publicPages/SignIn/SignIn';
import Profile from '../pages/userPages/Profile/Profile';

export interface IRoute {
  path: string;
  component: JSX.Element;
}

export enum RouteNames {
  LOGIN = '/login',
  REGISTER = '/register',
  RESET = '/reset',
  HOME = '/',
  PROFILE = '/profile',
  TARIFF_JOIN = '/tariff_join',
  TELEGRAM = '/telegram',
  SUPPORT = '/support',
  INTEGRATIONS = '/integrations',
  HELP = '/help',
  CONFIRM = '/confirm',
  NEW_PASSWORD = '/new_password',
  NOT_FOUND = '*',
  ADMIN_NOTIFICATIONS = '/admin/notifications',
  ADMIN_TICKETS = '/admin/tickets',
  ADMIN_TICKET_DETAIL = '/admin/ticketdetail',
  ADMIN_ANALYTICS = '/admin/analytics',
  ADMIN_SETTINGS = '/admin/settings',
  ADMIN_USERS = '/admin/users',
  ADMIN_USERS_ANALYTICS = '/admin/users/analytics',
  ADMIN_CONTENT = '/admin/content',
  PROVIDER_CALLBACK = '/callback',
  SEGMENTS = '/segments',
  CLIENTS = '/clients',
}

export const publicRoutes: IRoute[] = [
  { path: RouteNames.LOGIN, component: <SignIn /> },
  { path: RouteNames.REGISTER, component: <SignUp /> },
  { path: RouteNames.RESET, component: <ResetPassword /> },
];

export const privateRoutes: IRoute[] = [
  { path: RouteNames.HOME, component: <HomePage /> },
  { path: RouteNames.CLIENTS + '/*', component: <Clients /> },
  { path: RouteNames.SEGMENTS, component: <Segments /> },
  { path: RouteNames.PROFILE, component: <Profile /> },
  { path: RouteNames.TELEGRAM, component: <Telegram /> },
  { path: RouteNames.SUPPORT, component: <Support /> },
  { path: RouteNames.INTEGRATIONS, component: <Integrations /> },
  { path: RouteNames.HELP + '/*', component: <Help /> },
  { path: RouteNames.ADMIN_TICKET_DETAIL, component: <TicketDetail />},
  { path: RouteNames.TARIFF_JOIN, component: <TariffJoin /> },

];

export const adminRoutes: IRoute[] = [
  { path: RouteNames.ADMIN_USERS, component: <Users /> },
  { path: RouteNames.ADMIN_USERS_ANALYTICS, component: <UsersAnalytics /> },
  { path: RouteNames.ADMIN_CONTENT + '/*', component: <Content /> },
  { path: RouteNames.ADMIN_NOTIFICATIONS + '/*', component: <Notifications /> },
  { path: RouteNames.ADMIN_TICKETS, component: <Tickets /> },
  { path: RouteNames.ADMIN_TICKET_DETAIL, component: <TicketDetail />},
  { path: RouteNames.ADMIN_ANALYTICS, component: <Analytics />},
  { path: RouteNames.ADMIN_SETTINGS + '/*', component: <Settings /> },
];

export const routes: IRoute[] = [
  { path: RouteNames.RESET + '/:token/', component: <ConfirmPassword /> },
  { path: RouteNames.CONFIRM + '/:token/', component: <ConfirmEmail /> },
  {
    path: RouteNames.PROVIDER_CALLBACK + '/:provName/',
    component: <ProviderCallback />,
  },
  { path: RouteNames.NEW_PASSWORD, component: <NewPassword /> },
  { path: RouteNames.NOT_FOUND, component: <NotFound /> },

];
