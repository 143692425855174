import { FC } from 'react'
import { AppShell } from '@mantine/core'

import LayoutFooter from './components/LayoutFooter/LayoutFooter'

interface ILayout {
    children?: React.ReactNode
}

const LayoutPublic: FC<ILayout> = (props) => {
    return <AppShell footer={<LayoutFooter />}>{props.children}</AppShell>
}

export default LayoutPublic
