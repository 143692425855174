import { createSlice } from '@reduxjs/toolkit';

import {
  createNotification,
  deleteNotificationById,
  deleteNotifications,
  getNotifications,
  getUsersForNotifications,
  readNotificationById,
  readNotifications,
} from './actions';
import { NotificationsState } from './types';

const initialState: NotificationsState = {
  notifications: null,
  loading: false,
  updating: false,
  error: false,
  creatingError: false,
  creatingMessage: null,
  users: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    recoveryNotificationsState(state) {
      state.loading = false;
      state.updating = false;
      state.error = false;
      state.creatingError = false;
      state.creatingMessage = null;
    },
  },
  extraReducers: (builder) => {
    // getNotifications
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = action.payload.results;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Внезапная ошибка';
    });

    // readNotifications
    builder.addCase(readNotifications.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(readNotifications.fulfilled, (state) => {
      state.updating = false;
      state.notifications?.forEach((item) => {
        item.read = true;
      });
    });
    builder.addCase(readNotifications.rejected, (state, action) => {
      state.updating = false;
      state.error = action.payload || 'Внезапная ошибка';
    });

    // deleteNotifications
    builder.addCase(deleteNotifications.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(deleteNotifications.fulfilled, (state) => {
      state.updating = false;
      state.notifications = [];
    });
    builder.addCase(deleteNotifications.rejected, (state, action) => {
      state.updating = false;
      state.error = action.payload || 'Внезапная ошибка';
    });

    // readNotificationById
    builder.addCase(readNotificationById.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(readNotificationById.fulfilled, (state, action) => {
      state.updating = false;
      if (state.notifications)
        state.notifications.filter((item) => item.id === action.payload.id)[0].read = true;
    });
    builder.addCase(readNotificationById.rejected, (state, action) => {
      state.updating = false;
      state.error = action.payload || 'Внезапная ошибка';
    });

    // deleteNotificationById
    builder.addCase(deleteNotificationById.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(deleteNotificationById.fulfilled, (state, action) => {
      state.updating = false;
      if (state.notifications)
        state.notifications.splice(
          state.notifications.findIndex((item) => item.id === action.payload.id),
          1
        );
    });
    builder.addCase(deleteNotificationById.rejected, (state, action) => {
      state.updating = false;
      state.error = action.payload || 'Внезапная ошибка';
    });

    // createNotification
    builder.addCase(createNotification.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(createNotification.fulfilled, (state, action) => {
      state.updating = false;
      state.creatingMessage = action.payload.message;
    });
    builder.addCase(createNotification.rejected, (state, action) => {
      state.updating = false;
      state.creatingError = action.payload || 'Внезапная ошибка';
    });
    // getUsersForNotifications
    builder.addCase(getUsersForNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersForNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload.results;
    });
    builder.addCase(getUsersForNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Внезапная ошибка';
    });
  },
});

export const notificationsActions = {
  getNotifications,
  readNotifications,
  deleteNotifications,
  readNotificationById,
  deleteNotificationById,
  createNotification,
  getUsersForNotifications,
  ...notificationsSlice.actions,
};

export default notificationsSlice.reducer;
