import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Button,
  FileInput,
  Grid,
  Group,
  Select,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { profile as profileAction } from '../../../store/action-creators/auth';
import { editProfile, uploadAvatar } from '../../../store/action-creators/profile';
import { getProvidersAuth } from '../../../store/action-creators/providersAuth';
import { resetProfileState } from '../../../store/entities/profile/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IProfileEditData } from '../../../types/IProfile';
import ThemesToggle from '../../../ui/ThemesToggle/ThemesToggle';
import { convertToDate } from '../../../utils/dateFormat';

import SettingsProviders from './components/SettingsProviders/SettingsProviders';

interface FormValuesTypes {
  email: string;
  description: string;
  date_of_birth: Date;
  sex: string;
  username: string;
}

const ProfileSettings = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.auth.user);
  const profileState = useAppSelector((state) => state.profile);
  const [avatar, setAvatar] = useState<string | undefined>();
  const [avatarFile, setAvatarFile] = useState<File>();
  const { t } = useTranslation();

  useEffect(() => {
    if (profile) {
      dispatch(getProvidersAuth());
    }
  }, []);

  const form = useForm<FormValuesTypes>({
    initialValues: {
      email: profile?.email || '',
      description: profile?.description || '',
      date_of_birth: (profile?.date_of_birth && convertToDate(profile.date_of_birth)) || new Date(),
      sex: profile?.sex || '',
      username: profile?.username || '',
    },

    validate: {
      email: () => (!profileState.formError?.email ? null : profileState.formError?.email[0]),
      description: () =>
        !profileState.formError?.description ? null : profileState.formError?.description[0],
      date_of_birth: () =>
        !profileState.formError?.date_of_birth ? null : profileState.formError?.date_of_birth[0],
      sex: () => (!profileState.formError?.sex ? null : profileState.formError?.sex[0]),
      username: () =>
        !profileState.formError?.username ? null : profileState.formError?.username[0],
    },
  });

  const handleChooseAvatar = (file: File) => {
    let reader = new FileReader();
    setAvatarFile(file);
    reader.readAsDataURL(file);
    reader.onload = function () {
      // @ts-ignore
      setAvatar(reader.result);
    };
  };

  const handleEditProfile = () => {
    let d = form.values.date_of_birth;
    let currDate = d.getDate();
    let currMonth = d.getMonth() + 1;
    let currYear = d.getFullYear();

    let date = currYear + '-' + currMonth + '-' + currDate;

    let data: IProfileEditData = {
      email: profile?.email === form.values.email ? undefined : form.values.email,
      description:
        profile?.description === form.values.description ? undefined : form.values.description,
      sex: profile?.sex === form.values.sex ? undefined : form.values.sex,
      username: profile?.username === form.values.username ? undefined : form.values.username,
      date_of_birth: profile?.date_of_birth === date ? undefined : date,
    };
    let a = Object.values(data).find((item) => item !== undefined);
    if (a) {
      dispatch(editProfile({ data: data }));
    }
  };

  useEffect(() => {
    if (profileState.isSuccess === 'EDIT_PROFILE') {
      showNotification({
        message: profileState.message,
        color: 'green',
      });
      dispatch(resetProfileState());
      dispatch(profileAction());
    } else if (profileState.isError === 'EDIT_PROFILE') {
      showNotification({
        message: profileState.message,
        color: 'red',
      });
      form.validate();
      dispatch(resetProfileState());
    }

    if (profileState.isSuccess === 'UPLOAD_AVATAR') {
      showNotification({
        message: profileState.message,
        color: 'green',
      });
      dispatch(resetProfileState());
      dispatch(profileAction());
    } else if (profileState.isError === 'UPLOAD_AVATAR') {
      showNotification({
        message: profileState.message,
        color: 'red',
      });
      dispatch(resetProfileState());
    }
  }, [profileState.isError, profileState.isSuccess]);

  return (
    <>
      <Group position={'apart'}>
        <Title order={2}>Edit profile</Title>
        <ThemesToggle />
      </Group>
      <Grid columns={24} gutter={30} style={{ marginTop: 5 }}>
        <Grid.Col md={16} sm={24}>
          <form onSubmit={form.onSubmit(handleEditProfile)}>
            <TextInput
              label="Email"
              placeholder="your@email.com"
              {...form.getInputProps('email')}
            />

            <TextInput
              style={{ marginTop: 10 }}
              label="Username"
              placeholder="your@email.com"
              {...form.getInputProps('username')}
            />

            <DatePicker
              style={{ marginTop: 10 }}
              placeholder="Pick date"
              label="Birthday"
              {...form.getInputProps('date_of_birth')}
            />

            <Select
              style={{ marginTop: 10 }}
              label="Sex"
              placeholder="Pick one"
              data={[
                { value: 'male', label: 'Male' },
                { value: 'female', label: 'Female' },
              ]}
              {...form.getInputProps('sex')}
            />

            <Textarea
              style={{ marginTop: 10 }}
              placeholder="Your description"
              label="Description"
              {...form.getInputProps('description')}
            />

            <Group position="left" mt="md">
              <Button type="submit" loading={profileState.isLoading === 'EDIT_PROFILE'}>
                Edit
              </Button>
            </Group>
          </form>

          <Group mt={15}>
            <Text>{t('ProfilePage.Settings.ConnectProviders')}</Text>
            <SettingsProviders />
          </Group>
        </Grid.Col>
        <Grid.Col md={8} sm={24}>
          <Group position={'center'}>
            <Avatar size={200} src={avatar || profile?.avatar_url} />
          </Group>
          <Group style={{ marginTop: 10 }} position={'center'}>
            <FileInput
              placeholder="Pick image"
              accept="image/png,image/jpeg"
              onChange={(file: File) => handleChooseAvatar(file)}
            />
          </Group>
          <Group style={{ marginTop: 10 }} position={'center'}>
            <Button
              size={'xs'}
              type="submit"
              onClick={() => avatarFile && dispatch(uploadAvatar({ file: avatarFile }))}
              loading={profileState.isLoading === 'UPLOAD_AVATAR'}
              disabled={!avatar}
            >
              Save
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default ProfileSettings;
