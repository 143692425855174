import { useEffect, useState } from 'react';

import { resetProvidersAuthState } from '../../../store/entities/auth/providerAuthSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IProviders } from '../../../types/IProviders';
import Providers from '../../../ui/Providers/Providers';
import { providerExist } from '../../../utils/getExistProviders';

const AuthProviders = () => {
  const providersAuthState = useAppSelector((store) => store.providers);
  const dispatch = useAppDispatch();
  const [providersAuth, setProvidersAuth] = useState<IProviders[]>([]);

  useEffect(() => {
    if (providersAuthState.isSuccess) {
      setProvidersAuth(providerExist(providersAuthState.providers));
      dispatch(resetProvidersAuthState());
    }
  }, [providersAuthState]);

  return <Providers providers={providersAuth} />;
};

export default AuthProviders;
