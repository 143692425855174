import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import {
    deleteCancelSubscriptionFetch,
    getListPlansFetch,
    getListPurchasesFetch,
    getSubscriptionViewFetch,
    ListPurchasesQuery,
    MakePurchaseData,
    patchSetAutoRenewalFetch,
    postMakePurchaseFetch,
    SetAutoRenewalData
} from '../../api/tariffApi';

// Отображение списка тарифных планов
export const getListPlans = createAsyncThunk('billing/plan/list-plans', async (arg, thunkAPI) => {
    try {
        const response = await getListPlansFetch();
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response?.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});
// Отображение покупок пользователя (параметры: номер страницы, количество на странице, сортировка, порядок сортировки)
export const getListPurchases = createAsyncThunk('billing/purchase/list-purchases/', async (arg: ListPurchasesQuery, thunkAPI) => {
    try {
        const response = await getListPurchasesFetch(arg);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});
// Покупка подписки
export const postMakePurchase = createAsyncThunk('billing/purchase/make-purchase/', async (arg: MakePurchaseData, thunkAPI) => {
    try {
        const response = await postMakePurchaseFetch(arg);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response?.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});
// Отмена подписки
export const deleteCancelSubscription = createAsyncThunk('billing/subscription/cancel-subscription/', async (arg, thunkAPI) => {
    try {
        const response = await deleteCancelSubscriptionFetch();
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response?.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});
// Включить/выключить автообновление подписки
export const patchSetAutoRenewal = createAsyncThunk('billing/subscription/set-auto-renewal', async (arg: SetAutoRenewalData, thunkAPI) => {
    try {
        const response = await patchSetAutoRenewalFetch(arg);
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response?.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});
// Просмотр текущей подписки
export const getSubscriptionView = createAsyncThunk('billing/subscription/subscription-view/', async (arg, thunkAPI) => {
    try {
        const response = await getSubscriptionViewFetch();
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response?.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});