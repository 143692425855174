import { combineReducers } from 'redux';

import authReducer from './entities/auth/authSlice';
import providersAuthReducer from './entities/auth/providerAuthSlice';
import helpReducer from './entities/help/helpSlice';
import noticesReducer from './entities/notices';
import notificationsReducer from './entities/notifications';
import profileReducer from './entities/profile/profileSlice';
import tariffReducer from './entities/tariff/tariffSlice';
import telegramReducer from './entities/telegram/telegramSlice';
import ticketReducer from './entities/ticket/ticketSlice';
import usersReducer from './entities/users/usersSlice';

// ROOT REDUCER EXAMPLE

// 1. Import your reducers from entities

// import cartReducer from './entities/cart/reducers';

// 2. Define reducers into common object
const rootReducer = combineReducers({
  auth: authReducer,
  telegram: telegramReducer,
  notices: noticesReducer,
  notifications: notificationsReducer,
  ticket: ticketReducer,
  users: usersReducer,
  providers: providersAuthReducer,
  tariff: tariffReducer,
  profile: profileReducer,
  help: helpReducer,
});

export default rootReducer;
export type IRootReducer = ReturnType<typeof rootReducer>;
