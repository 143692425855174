const options: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const dateFormat = (joinDate: string, locale: string) => {
  let dateInMSec = Date.parse(joinDate);
  let date = new Date(dateInMSec);
  return date.toLocaleString(locale, options);
};

export const convertToDate = (joinDate: string): Date => {
  return new Date(joinDate.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'));
};
