import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAutoRenewalDataResponce } from "src/api/tariffApi";
import { deleteCancelSubscription, getListPlans, getListPurchases, getSubscriptionView, patchSetAutoRenewal, postMakePurchase } from "src/store/action-creators/tariff";

export interface IRestriction {
    "Publish news": boolean;
    "View news": boolean;
}

export interface IPlan {
    description: string;
    period: number;
    price: number;
    restrictions: IRestriction;
    slug: string;
    tariff_plan_id: number;
    title: string;
}


export interface IListPlans {
    message: string;
    results: Array<IPlan>;
}

export interface IMakePurchase {
    slug: string;
}

export interface IPurchasesPagination {
    has_next: boolean;
    has_prev: boolean;
    items_per_page: number;
    next_page: null | number;
    page: number;
    prev_page: null | number;
    total_items: number;
}

export interface IPurchasesListResults {
    id: number;
    purchase_amount: null | number;
    purchase_date: null | string;
    slug: null | string;
    status: null | string;
    user_data: null | object;
}

export interface IPurchasesSorting {
    sort_by: string;
    sort_order: string;
}

export interface IPurchasesList {
    message: string;
    pagination: IPurchasesPagination;
    results: Array<IPurchasesListResults>;
    sorting: IPurchasesSorting;
}

export interface ISubscriptionView {
    auto_renewal: boolean;
    finish_date: string;
    id: number;
    plan: IPlan;
    start_date: string;
    tariff_plan_id: number;
    user_id: number;
}

export interface TariffState {
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    message: string,
    getListPlans: null | IListPlans;
    purchasesList: null | IPurchasesList;
    viewSubscription: null | ISubscriptionView;
    makePurchaseData: null | IMakePurchase;
    autoRenewalData: null | IAutoRenewalDataResponce;
    tariffJoin: string;
}

const initialState: TariffState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '',
    getListPlans: null,
    purchasesList: null,
    makePurchaseData: null,
    viewSubscription: null,
    autoRenewalData: null,
    tariffJoin: '',
};

export const tariffSlice = createSlice({
    name: 'tariff',
    initialState,
    reducers: {
        resetTariffState: () => initialState,

        setTariffJoinSlug: (state, action: PayloadAction<string>) => {
            state.tariffJoin = action.payload;
        },

    },

    extraReducers: {
        //Список тарифных планов
        [getListPlans.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getListPlans.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.getListPlans = action.payload;
        },
        [getListPlans.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },

        // Список оплаченных тарифных планов 
        [getListPurchases.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getListPurchases.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            // state.message = message;
            state.purchasesList = action.payload;
        },
        [getListPurchases.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Покупка тарифного плана 
        [postMakePurchase.pending.type]: (state) => {
            state.isLoading = true;
        },
        [postMakePurchase.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            // state.message = message;
            state.makePurchaseData = action.payload;
        },
        [postMakePurchase.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
         // Отмена текущего тарифного плана 
         [deleteCancelSubscription.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteCancelSubscription.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
        },
        [deleteCancelSubscription.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        //Просмотр текущей подписки
        [getSubscriptionView.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getSubscriptionView.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.viewSubscription = action.payload;
        },
        [getSubscriptionView.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Автопродление 
        [patchSetAutoRenewal.pending.type]: (state) => {
            state.isLoading = true;
        },
        [patchSetAutoRenewal.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.autoRenewalData = action.payload;
        },
        [patchSetAutoRenewal.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },

    }

});

export const {
    resetTariffState,
    // setTariffCard,
    // setSortOrder,
    // setSortBy,
    // setActivePage,
    setTariffJoinSlug,

} = tariffSlice.actions;

export default tariffSlice.reducer;
