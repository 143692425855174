import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, Tooltip } from '@mantine/core';
import { useAppSelector } from 'src/store/hooks';

import TariffCancelConfirmModal from './TariffCancelConfirmModal';

const TariffCancelSubscription: FC = () => {
    const { t } = useTranslation();
    const { viewSubscription } = useAppSelector((state) => state.tariff)
    const [openedConfirm, setOpenedConfirm] = useState(false);

    return (
        <>
            <Tooltip
                label={t('ProfilePage.Profile.Disable tariff')}
                color="dark"
                withArrow
            >
                <CloseButton
                    disabled={!viewSubscription?.finish_date}
                    radius="xl"
                    size="xs"
                    color="red"
                    variant="outline"
                    onClick={() => setOpenedConfirm(true)}
                /></Tooltip>
            <TariffCancelConfirmModal
                opened={openedConfirm}
                setOpened={setOpenedConfirm} />
        </>
    );
};

export default TariffCancelSubscription;