import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay } from '@mantine/core';
import { IPurchasesListResults } from 'src/store/entities/tariff/tariffSlice'
import { useAppSelector } from 'src/store/hooks';

import Table from '../../../userPages/Profile/ProfileTariff/TableTariff'
export const TariffPurchasesView: FC = () => {
    const { t } = useTranslation();
    //   const dispatch = useAppDispatch();
    const { isLoading, purchasesList } = useAppSelector((state) => state.tariff);

    const formatDate = (value: string) => {
        const parsedDateMs = Date.parse(value);
        return new Date(parsedDateMs).toLocaleDateString('ru-Ru');
    };

    const getCellNodeByKey = (key: string, value: any, row: any) => {
        switch (key) {
            case 'id':
                return row[key];

            case 'purchase_amount':
                return row[key];

            case 'purchase_date':
                return formatDate(row[key]);

            case 'slug':
                return row[key];

            case 'status':
                return row[key];

            default:
                return value;
        }
    };

    const getRowData = (row: any) => {
        const rowData: any = {};
        Object.keys(row).forEach((key) => {
            rowData[key] = getCellNodeByKey(key, row[key], row);
        });
        return rowData;
    };

    const columns = [
        { id: 'id', text: 'id' },
        { id: 'purchase_amount', text: t('ProfilePage.Profile.Price') },
        { id: 'purchase_date', text: t('ProfilePage.Profile.Start') },
        { id: 'slug', text: t('ProfilePage.Profile.Profile tariff') },
        { id: 'status', text: t('ProfilePage.Profile.Status') },

    ];

    const data = !purchasesList ? [] : purchasesList?.results.map((row: IPurchasesListResults) =>
        getRowData(row)) as IPurchasesListResults[];

    return (
        <>
            <Table tableInstance={{ columns, data }} />
            {
                isLoading && <LoadingOverlay
                    style={{ position: 'fixed' }}
                    loaderProps={{ variant: 'dots' }}
                    overlayOpacity={0.2}
                    zIndex={100}
                    visible
                />
            }
        </>
    );
};

export default TariffPurchasesView;
