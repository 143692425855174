import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import {
    addComment,
    AddCommentData,
    addStatus,
    AddStatusData,
    addTheme,
    AddThemeData,
    addTicket,
    AddTicketData,
    AddTicketDataManager,
    addTicketManager,
    changeManager,
    ChangeManagerData,
    changeOrder,
    ChangeOrderData,
    changeStatus,
    ChangeStatusData,
    DeleteCommentData,
    // closeTickets,
    // CloseTicketsData,
    deleteCommentFetch,
    getListTickets,
    getStatuses,
    getThemes,
    getTicketFields,
    ListTicketsData,
    // deleteStatus,
    // DeleteStatusData,
    // deleteTheme,
    // DeleteThemeData,
    // deleteTickets,
    // DeleteTicketsData,
    viewTickets,
    // updateStatus,
    // UpdateStatusData,
    // updateTheme,
    // UpdateThemeData,
    ViewTicketsData} from '../../api/ticketApi';

    // Изменение списка менеджеров
export const patchManager = createAsyncThunk('ticket/patchManager', async (arg: ChangeManagerData, thunkAPI) => {
  try {
      const response = await changeManager(arg);
      return response.data;
  } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data);
      } else {
          return thunkAPI.rejectWithValue(error.response?.statusText);
      }
  }
});
// Отображение списка статусов и их id
export const statusesList = createAsyncThunk('ticket/statusesList', async (arg, thunkAPI) => {
    try {
        const response = await getStatuses();
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if(error.response?.data) {
            return thunkAPI.rejectWithValue(error.response?.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});
// Отображение списка тем и их id
export const getThemesList = createAsyncThunk('ticket/themesList', async (arg, thunkAPI) => {
    try {
        const response = await getThemes();
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if(error.response?.data) {
            return thunkAPI.rejectWithValue(error.response?.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});
// Отображение списка полей модели "тикет" для сортировки
export const listTicketFields = createAsyncThunk('ticket/ticketFieldsListList', async (arg, thunkAPI) => {
    try {
        const response = await getTicketFields();
        return response.data;
    } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data);
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText);
        }
    }
});
// Добавление статуса
export const postStatus = createAsyncThunk('auth/postStatus', async (arg: AddStatusData, thunkAPI) => {
    try {
      const response = await addStatus(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  });
// Добавление темы
  export const postTheme = createAsyncThunk('auth/postTheme', async (arg: AddThemeData, thunkAPI) => {
    try {
      const response = await addTheme(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  });
// Создание тикета
  export const postTicket = createAsyncThunk('auth/postTicket', async (arg: AddTicketData, thunkAPI) => {
    try {
      const response = await addTicket(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  });
  // Создание тикета менеджером
  export const postTicketManager = createAsyncThunk('auth/postTicketManager', async (arg: AddTicketDataManager, thunkAPI) => {
    try {
      const response = await addTicketManager(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  });
  // Отображение списка тикетов по параметрам (по менеджеру, пользователю, статусу, рейтингу, дате создания)
export const listTicketsData = createAsyncThunk('ticket/listTicketsData', async (arg: ListTicketsData | undefined, thunkAPI) => {
  try {
      const response = await getListTickets(arg);
      return response.data;
  } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data);
      } else {
          return thunkAPI.rejectWithValue(error.response?.statusText);
      }
  }
});

export const viewTicketsData = createAsyncThunk('ticket/viewTicketData', async (arg: ViewTicketsData, thunkAPI) => {
  try {
      const response = await viewTickets(arg);
      return response.data;
  } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data);
      } else {
          return thunkAPI.rejectWithValue(error.response?.statusText);
      }
  }
});

// Изменение списка менеджеров
export const patchStatus = createAsyncThunk('ticket/patchStatus', async (arg: ChangeStatusData, thunkAPI) => {
  try {
      const response = await changeStatus(arg);
      return response.data;
  } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data);
      } else {
          return thunkAPI.rejectWithValue(error.response?.statusText);
      }
  }
});

// Изменение порядка статуса
export const patchOrder = createAsyncThunk('ticket/patchOrder', async (arg: ChangeOrderData, thunkAPI) => {
  try {
      const response = await changeOrder(arg);
      return response.data;
  } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data);
      } else {
          return thunkAPI.rejectWithValue(error.response?.statusText);
      }
  }
});

// Добавить комментарий
export const patchComment = createAsyncThunk('ticket/patchComment', async (arg: AddCommentData, thunkAPI) => {
  try {
      const response = await addComment(arg);
      return response.data;
  } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data);
      } else {
          return thunkAPI.rejectWithValue(error.response?.statusText);
      }
  }
});

// Удаление комментария
export const deleteComment = createAsyncThunk('ticket/deleteComment', async (arg: DeleteCommentData, thunkAPI) => {
  try {
      const response = await deleteCommentFetch(arg);
      return response.data;
  } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
          return thunkAPI.rejectWithValue(error.response.data);
      } else {
          return thunkAPI.rejectWithValue(error.response?.statusText);
      }
  }
});