import { FC } from 'react';
import { Group, useMantineTheme } from '@mantine/core';
import { ReactComponent as Logo } from 'src/assets/icons/logo-pic.svg';
import { ReactComponent as LogoText } from 'src/assets/icons/logo-text.svg';
import { ReactComponent as LogoWhiteText } from 'src/assets/icons/logo-text-white.svg';

import styles from './LayoutNavbarLogo.module.scss';

interface ILayoutNavbarLogo {
  opened?: boolean;
}

const LayoutNavbarLogo: FC<ILayoutNavbarLogo> = ({ opened = true }) => {
  const theme = useMantineTheme();

  return (
    <div className={styles.root}>
      <Group spacing={10} noWrap>
        <div className={styles.icon}>
          <Logo width={24} height={24} />
        </div>
        {opened &&
          (theme.colorScheme === 'light' ? (
            <LogoText width={94} height={24} />
          ) : (
            <LogoWhiteText width={94} height={24} />
          ))}
      </Group>
    </div>
  );
};

export default LayoutNavbarLogo;
