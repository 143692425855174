import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, AppShell, Container } from '@mantine/core';
import { hideNotification, showNotification } from '@mantine/notifications';
import { NoticesTypeId } from 'src/constants/notices/notices';
import { useActions, useAppSelector } from 'src/store/hooks';
import { AlertCircle } from 'tabler-icons-react';

import LayoutHeader from './components/LayoutHeader/LayoutHeader';
import LayoutNavbar from './components/LayoutNavbar/LayoutNavbar';

interface ILayout {
  children?: React.ReactNode;
  title?: string;
  back?: string;
}

const Layout: FC<ILayout> = (props) => {
  const location = useLocation();

  const { getNoticesByUrl } = useActions();

  const { noticesUrl, noticesByUrl } = useAppSelector((store) => store.notices);

  useEffect(() => {
    noticesByUrl?.forEach((item) => {
      hideNotification(`notice-${item.id}`);
    });
    getNoticesByUrl({ url_page: location.pathname });
  }, [location.pathname]);

  useEffect(() => {
    if (noticesUrl === location.pathname) {
      noticesByUrl
        ?.filter((item) => !!item.active)
        ?.filter((item) => item.type_notice === NoticesTypeId.Notification)
        .forEach((item) => {
          showNotification({
            id: `notice-${item.id}`,
            autoClose: false,
            message: item.body,
          });
        });
    }
  }, [noticesUrl]);

  return (
    <AppShell navbar={<LayoutNavbar />} fixed={false} padding={0}>
      <LayoutHeader title={props.title} back={props.back} />

      {noticesUrl === location.pathname &&
        noticesByUrl
          ?.filter((item) => !!item.active)
          ?.filter((item) => item.type_notice === NoticesTypeId.Alert)
          .map((item) => (
            <Alert
              key={item.id}
              mx={10}
              mt={10}
              variant="outline"
              icon={<AlertCircle size={16} />}
              children={item.body}
            />
          ))}

      <Container fluid={true} py={16}>
        {props.children}
      </Container>
    </AppShell>
  );
};

export default Layout;
