import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { noticesApi } from 'src/api/noticesApi';

import {
  CreateNoticeData,
  GetNoticesByUrlData,
  GetNoticesParams,
  NoticesState,
  SetNoticeActivityData,
  UpdateNoticeData,
} from './types';

export const getNoticesTypes = createAsyncThunk<any, void, { rejectValue: string }>(
  'notices/getNoticesTypes',
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await noticesApi.getNoticesTypes();
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getNotices = createAsyncThunk<any, GetNoticesParams, { rejectValue: string }>(
  'notices/getNotices',
  async (params: GetNoticesParams, { rejectWithValue }) => {
    try {
      const { data } = await noticesApi.getNotices(params);
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const createNotice = createAsyncThunk<any, CreateNoticeData, { rejectValue: string }>(
  'notices/createNotice',
  async (formData, { getState, dispatch, rejectWithValue }) => {
    try {
      const { notices: { pagination }} = getState() as { notices: NoticesState };

      const { data } = await noticesApi.createNotice(formData);
      dispatch(getNotices({ page: pagination?.page, items_per_page: pagination?.items_per_page }));

      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const updateNotice = createAsyncThunk<any, UpdateNoticeData, { rejectValue: string }>(
  'notices/updateNotice',
  async (formData, { getState, dispatch, rejectWithValue }) => {
    try {
      const { notices: { pagination }} = getState() as { notices: NoticesState };

      const { data } = await noticesApi.updateNotice(formData);
      dispatch(getNotices({ page: pagination?.page, items_per_page: pagination?.items_per_page }));

      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const deleteNotice = createAsyncThunk<any, number, { rejectValue: string }>(
  'notices/deleteNotice',
  async (id: number, { getState, dispatch, rejectWithValue }) => {
    try {
      const { notices: { pagination }} = getState() as { notices: NoticesState };
      const page =
        pagination &&
        pagination.prev_page !== null &&
        !pagination.has_next &&
        pagination.total_items % pagination.items_per_page === 1
          ? pagination.prev_page
          : pagination?.page;

      const { data } = await noticesApi.deleteNotice(id);
      dispatch(getNotices({ page, items_per_page: pagination?.items_per_page }));

      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const setNoticeActivity = createAsyncThunk<any, SetNoticeActivityData, { rejectValue: string }>(
  'notices/setNoticeActivity',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await noticesApi.setNoticeActivity(formData);
      return { ...formData, ...data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getNoticesByUrl = createAsyncThunk<any, GetNoticesByUrlData, { rejectValue: string }>(
  'notices/getNoticesByUrl',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await noticesApi.getNoticesByUrl(formData);
      return { ...formData, ...data };
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);
