import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Group, Menu, Text } from '@mantine/core';
import { DoorExit, DoorOff, Eyeglass, Language, User } from 'tabler-icons-react';

interface ProfileMenuProps {
  handleLogout: () => void;
  logoutEmulation: () => void;
  handleProfile: () => void;
  handleTariff: () => void;
  handleLanguages: () => void;
}

const ProfileMenu: FC<ProfileMenuProps> = ({
  handleLogout,
  logoutEmulation,
  handleProfile,
  handleTariff,
  handleLanguages,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Menu width={200} shadow="md">
        <Menu.Target>
          <Button
            variant="outline"
            pt={3}
            leftIcon={
              JSON.parse(localStorage.getItem('userToken') || '{}')?.tokenEmulate && (
                <Eyeglass size={20} />
              )
            }
          >
            {t('Layout.Profile')}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item icon={<User size={20} />} onClick={() => handleProfile()}>
            <Text pt={3}>{t('Layout.Profile')}</Text>
          </Menu.Item>
          <Menu.Item onClick={() => handleTariff()}>
            <Group>
              <Text
                style={{
                  fontSize: 22,
                  paddingLeft: 3,
                }}
              >
                {i18n.language === 'ru' ? <>&#8381;</> : <>&#8364;</>}
              </Text>
              <Text>{t('Layout.Tariff')}</Text>
            </Group>
          </Menu.Item>
          <Menu.Item icon={<Language size={20} />} onClick={() => handleLanguages()}>
            {t('Layout.Language')}
          </Menu.Item>
          <Menu.Item icon={<DoorExit size={20} color="red" />} onClick={() => handleLogout()}>
            {t('Layout.Logout')}
          </Menu.Item>
          {JSON.parse(localStorage.getItem('userToken') || '{}')?.tokenEmulate && (
            <>
              <Divider my="sm" style={{ marginTop: 5, marginBottom: 0 }} />
              <Menu.Item
                icon={<DoorOff size={20} color={'#1971c2'} />}
                onClick={() => logoutEmulation()}
              >
                {t('Layout.logoutUserEmulation')}
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default ProfileMenu;
