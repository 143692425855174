import { FC, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';

import { RolesEnum } from '../constants/roles/roles';
import { profile } from '../store/action-creators/auth';
import { reset } from '../store/entities/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';

import { RouteNames } from './routes-enums';

interface Props {
  children: JSX.Element;
}

const AdminRoutes: FC<Props> = ({ children }) => {
  const token = JSON.parse(localStorage.getItem('userToken') || '{}');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, user, isSuccessProfileLoad } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (token?.token) {
      dispatch(profile());
    }
  }, []);

  useEffect(() => {
    if (isSuccessProfileLoad) {
      if (user?.role !== RolesEnum.SUPERUSER && user?.role !== RolesEnum.ADMIN) {
        navigate(RouteNames.HOME);
      }
    }
  }, [isSuccessProfileLoad]);

  if (isLoading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader variant="dots" />
      </Center>
    );
  }

  if (!token.token) {
    dispatch(reset());
  }

  return token.token ? children : <Navigate to={RouteNames.LOGIN} />;
};

export default AdminRoutes;
