import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  DeleteTelegramAccountData,
  deleteTelegramAccountFetch,
  getQRTelegramFetch,
  getTelegramAccountsFetch,
  getUrlTelegramFetch,
  NotificationStatusData,
  setTelegramNotificationFetch,
} from '../../api/telegramApi';

export const getUrlTelegram = createAsyncThunk('telegram/getUrlTelegram', async (arg, thunkAPI) => {
  try {
    const response = await getUrlTelegramFetch();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const getQRTelegram = createAsyncThunk('telegram/getQRTelegram', async (arg, thunkAPI) => {
  try {
    const response = await getQRTelegramFetch();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const setTelegramNotification = createAsyncThunk(
  'telegram/setTelegramNotification',
  async (arg: NotificationStatusData, thunkAPI) => {
    try {
      const response = await setTelegramNotificationFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const getTelegramAccounts = createAsyncThunk(
  'telegram/getTelegramAccounts',
  async (arg, thunkAPI) => {
    try {
      const response = await getTelegramAccountsFetch();
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const deleteTelegramAccount = createAsyncThunk(
  'telegram/deleteTelegramAccount',
  async (arg: DeleteTelegramAccountData, thunkAPI) => {
    try {
      const response = await deleteTelegramAccountFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
