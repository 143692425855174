import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  ConfirmTokenData,
  confirmTokenUser,
  getProfile,
  LoginData,
  loginUser,
  logoutFetch,
  RegisterData,
  registerUser,
  ResetPasswordData,
  resetPasswordUser,
  SetNewPasswordData,
  setNewPasswordUser,
} from '../../api/authApi';

export const profile = createAsyncThunk('auth/profile', async (arg, thunkAPI) => {
  try {
    const response = await getProfile();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const register = createAsyncThunk('auth/register', async (arg: RegisterData, thunkAPI) => {
  try {
    const response = await registerUser(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const login = createAsyncThunk('auth/login', async (arg: LoginData, thunkAPI) => {
  try {
    const response = await loginUser(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (arg: ResetPasswordData, thunkAPI) => {
    try {
      const response = await resetPasswordUser(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const confirmToken = createAsyncThunk(
  'auth/confirmToken',
  async (arg: ConfirmTokenData, thunkAPI) => {
    try {
      const response = await confirmTokenUser(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const setNewPassword = createAsyncThunk(
  'auth/setNewPassword',
  async (arg: SetNewPasswordData, thunkAPI) => {
    try {
      const response = await setNewPasswordUser(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const logoutUserAction = createAsyncThunk(
  'auth/logout',
  async (arg: { token: string }, thunkAPI) => {
    try {
      const response = await logoutFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
