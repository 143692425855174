import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  getProvidersAuthFetch,
  getTokenProviderFetch,
  IGetTokenProvider,
  unlinkProviderFetch,
} from '../../api/providersAuthApi';

export const getProvidersAuth = createAsyncThunk(
  'providersAuth/getProvidersAuth',
  async (arg, thunkAPI) => {
    try {
      const response = await getProvidersAuthFetch();
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const getTokenProvider = createAsyncThunk(
  'providersAuth/getTokenProvider',
  async (arg: IGetTokenProvider, thunkAPI) => {
    try {
      const response = await getTokenProviderFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const unlinkProvider = createAsyncThunk(
  'providersAuth/unlinkProvider',
  async (arg: { providerName: string }, thunkAPI) => {
    try {
      const response = await unlinkProviderFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
