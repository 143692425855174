import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Select, Space, Text } from '@mantine/core';
import TicketsCommentsAdd from 'src/pages/adminPages/Tickets/TicketsCommentsAdd';
import { getThemesList, postTicket } from 'src/store/action-creators/ticket';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Notes } from 'tabler-icons-react';

import styles from './SupportOverlay.module.scss';

interface SupportDrawerProps {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

const SupportDrawer: FC<SupportDrawerProps> = ({ opened, setOpened }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [valueTheme, setValueTheme] = useState<any>('');
  const [valueDescription, setValueDescription] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [sendComment, setSendComment] = useState<boolean>(false);
  const { themesList } = useAppSelector((state) => state.ticket);
  const themeTitle = themesList?.detailed_results?.map((result) => ({
    value: result.id.toString(),
    label: result.title,
  })) as { value: string; label: string }[];

  const clearState = () => {
    setValueDescription('');
    setSendComment(false);
    setImageFile(null);
    setValueTheme('');
  };

  useEffect(() => {
    if (sendComment) {
      dispatch(
        postTicket({
          description: valueDescription,
          file: '',
          theme_id: parseInt(valueTheme, 10),
        })
      );
      clearState();
      setOpened(false);
    };
  }, [sendComment]);

  useEffect(() => {
    dispatch(getThemesList());
  }, []);

  return (
    <Drawer
      classNames={{ drawer: styles.drawer, title: styles.title }}
      title={t('SupportDrawer.Title')}
      onClose={() => setOpened(false)}
      transitionDuration={500}
      overlayOpacity={0.5}
      position="right"
      opened={opened}
      padding="xl"
      size={680}
    >
      <Text mb={20}>{t('SupportDrawer.Message')}</Text>

      <form>
        <Select
          styles={{ rightSection: { pointerEvents: 'none' } }}
          placeholder={t('SupportDrawer.ThemePlaceholder')}
          classNames={{ dropdown: styles.selectDropdown }}
          style={{ msOverflowStyle: 'none' }}
          rightSection={<Notes size={22} />}
          rightSectionWidth={30}
          value={valueTheme}
          data={themeTitle}
          size="md"
          required
          onChange={setValueTheme}
        />
        <Space h='sm' />
        <TicketsCommentsAdd
          imageFile={imageFile}
          sendComment={sendComment}
          setImageFile={setImageFile}
          setSendComment={setSendComment}
          valueDescription={valueDescription}
          setValueDescription={setValueDescription}
        />
      </form>
    </Drawer>
  );
};

export default SupportDrawer;
