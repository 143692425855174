import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

import translationDE from '../assets/locales/de/translation.json';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import translationEN from '../assets/locales/en/translation.json';
import translationES from '../assets/locales/es/translation.json';
import translationID from '../assets/locales/id/translation.json';
import translationPT from '../assets/locales/pt/translation.json';
import translationRU from '../assets/locales/ru/translation.json';
import translationTH from '../assets/locales/th/translation.json';
import translationVN from '../assets/locales/vn/translation.json';

const resources = {
  ru: {
    translation: translationRU,
  },
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
  id: {
    translation: translationID,
  },
  th: {
    translation: translationTH,
  },
  vn: {
    translation: translationVN,
  },
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'ru',
    supportedLngs: ['en', 'ru', 'de', 'pt', 'es', 'id', 'th', 'vn'],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
