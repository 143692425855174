import axios from 'axios';

export const BASE_URL: string = process.env.REACT_APP_BASE_API_URL as string;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const objectToJson = (obj: Record<string, unknown>): string => {
  return JSON.stringify(obj, null, 0);
};

axiosInstance.interceptors.request.use(
  (request) => {
    if (!request?.headers) {
      throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
    }
    const token = JSON.parse(localStorage.getItem('userToken') || '{}')?.token;
    const emulateToken = JSON.parse(localStorage.getItem('userToken') || '{}')?.tokenEmulate;
    request.headers.Authorization = 'Basic ' + btoa(token + ':');
    if (emulateToken) {
      request.headers['Authorization-other-user'] = emulateToken;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Type response middleware here
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
