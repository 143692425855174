import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Text } from '@mantine/core';
import { getListPlans, getListPurchases, getSubscriptionView } from 'src/store/action-creators/tariff';
import { useAppDispatch } from 'src/store/hooks';
import { ArrowLeft } from 'tabler-icons-react';

import TariffCard from './TariffCard';
import TariffPurchasesView from './TariffPurchasesView';

const ProfileTariff = () => {
    const { t } = useTranslation();
    const [openTable, setOpenTable] = useState(true)
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getListPlans());
        dispatch(getListPurchases({ page: 1, sort_by: 'id', sort_order: 'desc' }));
        dispatch(getSubscriptionView());
    }, []);

    return (
        <>
            {
                openTable ?
                    <>
                        <TariffCard />
                        <Button radius="md" onClick={() => setOpenTable(false)}>
                        {t('ProfilePage.Profile.PurchasesView')}
                        </Button>
                    </>
                    :
                    <>
                        <Group position='apart'>
                            <Text size="xl">{t('ProfilePage.Profile.PurchasesHistory')}</Text>
                            <Button
                                radius="md"
                                onClick={() => setOpenTable(true)}
                            ><ArrowLeft size={22} />
                            </Button>
                        </Group>
                        <TariffPurchasesView />
                    </>
            }
        </>
    )
}
export default ProfileTariff;