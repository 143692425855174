import {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Center, Loader, Stack, Text} from '@mantine/core';

import {RouteNames} from '../../../routes/routes-enums';
import {getTokenProvider} from '../../../store/action-creators/providersAuth';
import {setUser} from '../../../store/entities/auth/authSlice';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {getAuthToken} from "../../../utils/localStorage";

const ProviderCallback: FC = () => {
  const {provName} = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get('code');
  const dispatch = useAppDispatch();
  const providers = useAppSelector((state) => state.providers);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getTokenProvider({providerName: provName, code: code}));
  }, []);

  useEffect(() => {
    if (providers.isSuccess) {
      if (!getAuthToken()) {
        dispatch(setUser({token: providers.providerToken}));
      }
      navigate(RouteNames.HOME);
    }
  }, [providers]);

  return (
    <Center style={{height: '100vh'}}>
      <Stack align={'center'}>
        {providers.isError ? (
          <Text>{t('ConfirmToken.Invalid token')}</Text>
        ) : (
          providers.isLoading && (
            <>
              <Loader variant="dots"/>
              <Text>{t('ConfirmToken.Request processing...')}</Text>
            </>
          )
        )}
      </Stack>
    </Center>
  );
};

export default ProviderCallback;
