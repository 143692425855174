import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  confirmToken,
  login,
  logoutUserAction,
  profile,
  register,
  resetPassword,
  setNewPassword,
} from '../../action-creators/auth';
import { IUser } from '../../types/IUser';

export interface AuthState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  confirmTokenFromEmail: null | string;
  message: null | string;
  user: null | IUser;
  token: string | null;
  isSuccessProfileLoad: boolean;
}

const initialState: AuthState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  confirmTokenFromEmail: null,
  message: null,
  user: null,
  token: null,
  isSuccessProfileLoad: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ token: string | null; tokenEmulate?: string | null }>
    ) => {
      localStorage.setItem(
        'userToken',
        JSON.stringify({
          token: action.payload.token,
          tokenEmulate: action.payload.tokenEmulate,
        })
      );
      state.token = action.payload.token;
      if (action.payload.tokenEmulate) {
        document.cookie = '';
      }
    },
    logoutUser: (state) => {
      localStorage.removeItem('userToken');
      state.token = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.confirmTokenFromEmail = null;
      state.message = null;
      state.user = null;
      state.isSuccessProfileLoad = false;
    },
    reset: (state) => {
      state.token = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.user = null;
      state.isSuccessProfileLoad = false;
    },
    resetResult: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isSuccessProfileLoad = false;
    },
    setTokenFromEmail: (state, action: PayloadAction<{ token: string | null }>) => {
      state.confirmTokenFromEmail = action.payload.token;
    },
  },

  extraReducers: {
    // Получить данные о пользователе
    [profile.pending.type]: (state) => {
      state.isLoading = true;
    },
    [profile.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
      state.isSuccessProfileLoad = true;
      i18n.changeLanguage(action.payload.locale);
    },
    [profile.rejected.type]: (state, action) => {
      localStorage.removeItem('userToken');
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isSuccessProfileLoad = false;
      state.message = action.payload.message || 'Внезапная ошибка';
    },

    // Регистрация
    [register.pending.type]: (state) => {
      state.isLoading = true;
    },
    [register.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action.payload.message || 'Внезапная ошибка';
    },
    [register.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      let messageError = <string[]>Object.values(action.payload.message)[0];
      state.message = messageError[0] || 'Внезапная ошибка';
    },

    // Вход
    [login.pending.type]: (state) => {
      state.isLoading = true;
    },
    [login.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.token = action.payload?.auth_token;
    },
    [login.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      let messageError = <string[]>Object.values(action.payload.message)[0];
      state.message = messageError[0] || 'Внезапная ошибка';
    },

    // Запрос на изменение пароля
    [resetPassword.pending.type]: (state) => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action.payload.message;
    },
    [resetPassword.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || 'Внезапная ошибка';
    },

    // Подтверждение токена
    [confirmToken.pending.type]: (state) => {
      state.isLoading = true;
    },
    [confirmToken.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.token = action.payload.auth_token;
      state.message = action.payload.message;
    },
    [confirmToken.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || 'Внезапная ошибка';
    },

    // Задать новый пароль
    [setNewPassword.pending.type]: (state) => {
      state.isLoading = true;
    },
    [setNewPassword.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action.payload.message;
      state.confirmTokenFromEmail = null;
    },
    [setNewPassword.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || 'Внезапная ошибка';
    },

    // Выход
    [logoutUserAction.pending.type]: (state) => {
      state.isLoading = true;
    },
    [logoutUserAction.fulfilled.type]: (state) => {
      localStorage.removeItem('userToken');
      state.token = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.confirmTokenFromEmail = null;
      state.message = null;
      state.user = null;
      state.isSuccessProfileLoad = false;
    },
    [logoutUserAction.rejected.type]: (state) => {
      localStorage.removeItem('userToken');
      state.token = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.confirmTokenFromEmail = null;
      state.message = null;
      state.user = null;
      state.isSuccessProfileLoad = false;
    },
  },
});

export const { setUser, logoutUser, reset, resetResult, setTokenFromEmail } = authSlice.actions;
export default authSlice.reducer;
