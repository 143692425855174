import { FC, useState } from 'react';
import { ActionIcon } from '@mantine/core';
import { Help } from 'tabler-icons-react';

import SupportDrawer from '../Drawer/SupportDrawer';

const SupportOverlay: FC = () => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <ActionIcon radius="xl" size={28} onClick={() => setOpened(true)}>
        <Help />
      </ActionIcon>

      <SupportDrawer opened={opened} setOpened={setOpened} />
    </>
  );
};

export default SupportOverlay;
