import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Card, CardSection, Container, Group, Text } from '@mantine/core';
import { RouteNames } from 'src/routes/routes-enums';
import { setTariffJoinSlug } from 'src/store/entities/tariff/tariffSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import ContentContainer from 'src/ui/ContentContainer/ContentContainer';
import { Eye, EyeOff, News, NewsOff } from 'tabler-icons-react';

export const TariffCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { getListPlans, viewSubscription } = useAppSelector((state) => state.tariff)
    const plansList = getListPlans?.results.map((result) =>
    ({
        description: result.description,
        period: result.period,
        price: result.price,
        restrictions: result.restrictions,
        slug: result.slug,
        tariffPlanId: result.tariff_plan_id,
        title: result.title
    }));

    return (
        <ContentContainer>
            <Container
                fluid={true}
                style={{ display: 'flex', flexWrap: 'wrap' }}
            >
                {
                    plansList?.map((result) => (
                        <div key={result.slug}
                            style={{
                                margin: 'auto',
                                marginTop: '5px',
                                marginBottom: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                width: '300px',
                            }}
                        >
                            <Card
                                withBorder
                                p="lg"
                                radius="md"
                            >
                                <CardSection style={{
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderBottom: `1px solid lightgray`
                                }}
                                >
                                    <Group>
                                        <Text
                                            size="xl"
                                            weight={700}
                                        >
                                            {result.title}
                                        </Text>
                                        <Badge
                                            size="xl"
                                            color="blue"
                                            variant="outline"
                                        >
                                            {result.period}
                                        </Badge>
                                        <Text weight={500}>
                                            {result.period === 1
                                                ? t('ProfilePage.Profile.Month')
                                                : (result.period === 2 || result.period === 3 || result.period === 4)
                                                    ? t('ProfilePage.Profile.Months')
                                                    : t('ProfilePage.Profile.MonthsPlus')}
                                        </Text>
                                    </Group>
                                </CardSection>

                                <Badge
                                    style={{
                                        margin: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        // borderBottom: `1px solid lightgray`
                                    }}
                                    size="xl"
                                    color="blue"
                                    variant="outline"
                                >
                                    {result.slug}
                                </Badge>

                                <Text size="md" color="dimmed">
                                    {result.description}
                                </Text>
                                <CardSection style={{
                                    padding: '5px 10px 5px 50px',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'left',
                                    justifyContent: 'left',
                                    // borderBottom: `1px solid lightgray`
                                }}
                                >
                                    <Text size="sm" color="dimmed">
                                        {`${result.restrictions['Publish news']}` === `true` ?
                                            <News color='#228be6' /> : <NewsOff />}
                                    </Text>
                                    <Text
                                        style={{ padding: '5px' }}
                                        size="xs"
                                        color="dimmed"
                                    >
                                        {t('ProfilePage.Profile.NewsPublication')}
                                    </Text>
                                </CardSection>
                                <CardSection style={{
                                    padding: '5px 10px 5px 50px',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'left',
                                    justifyContent: 'left',
                                    borderBottom: `1px solid lightgray`
                                }}
                                >
                                    <Text size="sm" color="dimmed">
                                        {`${result.restrictions['View news']}` === `true` ?
                                            <Eye color='#228be6' /> : <EyeOff />}
                                    </Text>
                                    <Text
                                        style={{ padding: '5px' }}
                                        size="xs"
                                        color="dimmed">
                                        {t('ProfilePage.Profile.NewsReading')}
                                    </Text>
                                </CardSection>
                                <CardSection
                                    style={{
                                        padding: '10px',
                                        // borderTop: `1px solid lightgray`
                                    }}
                                >
                                    <Group spacing={30}>
                                        <div>
                                            <Text
                                                size="xl"
                                                weight={700}
                                                sx={{ lineHeight: 1 }}
                                            >
                                                {result.price} ₽
                                            </Text>
                                            <Text
                                                size="sm"
                                                color="dimmed"
                                                weight={500}
                                                sx={{ lineHeight: 1 }}
                                                mt={3}
                                            >
                                                {t('ProfilePage.Profile.InAllTime')}
                                            </Text>
                                        </div>

                                        <Button
                                            disabled={!!viewSubscription?.finish_date 
                                                || (!viewSubscription?.tariff_plan_id && result.slug === 'basicplan')}
                                            radius="md"
                                            style={{ flex: 1 }}
                                            onClick={() => {
                                                dispatch(setTariffJoinSlug(result.slug));
                                                navigate(RouteNames.TARIFF_JOIN);
                                            }}
                                        >
                                            {t('ProfilePage.Profile.Join')}
                                        </Button>
                                    </Group>
                                </CardSection>
                            </Card>
                        </div>
                    )).slice(0, 2)
                }
            </Container>

            <Container>

            </Container>
        </ContentContainer >
    );
};

export default TariffCard;