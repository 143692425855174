import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionIcon,
  Alert,
  Button,
  Divider,
  Group,
  Loader,
  Popover,
  ScrollArea,
  Text,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useActions, useAppSelector } from 'src/store/hooks';
import { Bell } from 'tabler-icons-react';

import NotificationsItem from '../NotificationsItem/NotificationsItem';

import styles from './NotificationsPopover.module.scss';

const NotificationsPopover: FC = () => {
  const { t } = useTranslation();

  const { notifications, loading, error } = useAppSelector((store) => store.notifications);

  const {
    getNotifications,
    readNotifications,
    deleteNotifications,
    readNotificationById,
    deleteNotificationById,
    recoveryNotificationsState,
  } = useActions();

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (notifications && error) {
      showNotification({ color: 'red', message: error });
      recoveryNotificationsState();
    }
  }, [error]);

  return (
    <>
      <Popover width={400} position="bottom" shadow="md" offset={10}>
        <Popover.Target>
          <ActionIcon radius="xl" size={28}>
            <Bell />
            {!!notifications?.filter((item) => !item.read)?.length && <span className={styles.badge}></span>}
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown className={styles.dropdown}>
          <Group className={styles.header} position="apart" mb={30}>
            <Title order={4}>{t('Layout.NotificationsPopover.Notifications')}</Title>

            {notifications?.length && (
              <Group spacing="xs">
                <Button className={styles.removeBtn} variant="subtle" color="gray" size="xs" compact onClick={deleteNotifications}>
                  {t('Layout.NotificationsPopover.Delete all')}
                </Button>
                <Button variant="subtle" color="gray" size="xs" compact onClick={readNotifications}>
                  {t('Layout.NotificationsPopover.Read all')}
                </Button>
              </Group>
            )}
          </Group>

          <ScrollArea className={styles.content} offsetScrollbars>
            {loading ? (
              <Loader variant="dots" />
            ) : !notifications && error ? (
              <Alert children={error} color="red" />
            ) : !notifications?.length ? (
              <Text>{t('Layout.NotificationsPopover.No new notifications')}</Text>
            ) : (
              notifications?.map((item, index) => (
                <React.Fragment key={index}>
                  <NotificationsItem
                    id={item.id}
                    date={item.notification.sent_at}
                    title={item.notification.title}
                    text={item.notification.body}
                    read={item.read}
                    onClickRead={readNotificationById}
                    onClickDelete={deleteNotificationById}
                  />
                  {index !== notifications.length - 1 && <Divider my="lg" color="#bfcedb" />}
                </React.Fragment>
              ))
            )}
          </ScrollArea>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
