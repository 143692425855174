import axios from 'axios';

import axiosInstance, { BASE_URL } from './axiosInstance';

const HOST = process.env.REACT_APP_HOST;

export interface RegisterData {
  username: string;
  email: string;
  password: string;
  password2: string;
}

export interface LoginData {
  email: string;
  password: string;
}

export interface ResetPasswordData {
  email: string;
}

export interface ConfirmTokenData {
  token: string;
}

export interface SetNewPasswordData {
  token: string | null;
  password1: string;
  password2: string;
}

export const getProfile = async () => {
  const response = await axiosInstance.get('/api/users/profile/');
  return response;
};

export const loginUser = async ({ email, password }: LoginData) => {
  const response = await axios.post(BASE_URL + '/api/auth/login/', {
    email: email,
    password: password,
  });
  return response;
};

export const registerUser = async ({ username, email, password, password2 }: RegisterData) => {
  const response = await axios.post(BASE_URL + '/api/auth/register/', {
    username: username,
    email: email,
    password: password,
    password2: password2,
    host: HOST,
  });
  return response;
};

export const resetPasswordUser = async ({ email }: ResetPasswordData) => {
  const response = await axios.post(BASE_URL + '/api/auth/reset/', {
    email: email,
    host: HOST,
  });

  return response;
};

export const confirmTokenUser = async ({ token }: ConfirmTokenData) => {
  const response = await axios.get(BASE_URL + '/api/auth/confirm/' + token + '/');
  return response;
};

export const setNewPasswordUser = async ({ token, password1, password2 }: SetNewPasswordData) => {
  const response = await axios.post(BASE_URL + '/api/auth/reset/' + token + '/', {
    password1: password1,
    password2: password2,
  });
  return response;
};

export const logoutFetch = async ({ token }: { token: string }) => {
  const response = await axios.get(BASE_URL + '/api/auth/logout/', {
    headers: {
      Authorization: 'Basic ' + btoa(token + ':'),
    },
  });
  return response;
};
