import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay, Space, Switch, Text } from '@mantine/core';
import { getSubscriptionView, patchSetAutoRenewal } from 'src/store/action-creators/tariff';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Repeat, RepeatOff } from 'tabler-icons-react';

function RenewSubscriptionToggle() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { viewSubscription, isLoading } = useAppSelector((state) => state.tariff);
  const [checked, setChecked] = useState(viewSubscription?.auto_renewal);

  const handlyRenewalChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.currentTarget.checked);
  }

  useEffect(() => {
    if (!(viewSubscription?.auto_renewal === undefined))
      dispatch(patchSetAutoRenewal({ auto_renewal: checked as boolean }));
    dispatch(getSubscriptionView());
    dispatch(getSubscriptionView());
  }, [checked]);

  return (
    <>
      {viewSubscription?.auto_renewal ?
        <Repeat size={22} />
        : <RepeatOff size={22} />}
      <Space w="md" />
      <Text>
        {t('ProfilePage.Profile.AutoRenewal')}
      </Text>
      <Space w="md" />
      <Switch
        disabled={!viewSubscription?.finish_date}
        checked={viewSubscription?.auto_renewal}
        onChange={(e) => handlyRenewalChecked(e)}
      />
      <Space w="md" />
      {viewSubscription?.auto_renewal ?
        <Text weight={700}>
          {t('ProfilePage.Profile.AutoRenewalOn')}
        </Text>
        : <Text weight={700}>
          {t('ProfilePage.Profile.AutoRenewalOff')}
          </Text>}
      {isLoading && <LoadingOverlay
        style={{ position: 'fixed' }}
        loaderProps={{ variant: 'dots' }}
        overlayOpacity={0.2}
        zIndex={100}
        visible
      />}
    </>
  );
}

export default RenewSubscriptionToggle;
