import {
  CreateNotificationData,
  DeleteNotificationsData,
  ReadNotificationsData,
} from 'src/store/entities/notifications/types';

import axiosInstance from './axiosInstance';

export const notificationsApi = {
  getNotifications() {
    return axiosInstance.request({
      method: 'get',
      url: 'api/notifications/',
    });
  },

  readNotifications(data: ReadNotificationsData) {
    return axiosInstance.request({
      method: 'patch',
      url: 'api/notifications/',
      data,
    });
  },

  deleteNotifications(data: DeleteNotificationsData) {
    return axiosInstance.request({
      method: 'delete',
      url: 'api/notifications/',
      data,
    });
  },

  readNotificationById(id: number) {
    return axiosInstance.request({
      method: 'patch',
      url: `api/notifications/${id}/`,
    });
  },

  deleteNotificationById(id: number) {
    return axiosInstance.request({
      method: 'delete',
      url: `api/notifications/${id}/`,
    });
  },

  createNotification(data: CreateNotificationData) {
    return axiosInstance.request({
      method: 'post',
      url: 'api/notifications/',
      data,
    });
  },

  getUsersForNotifications() {
    return axiosInstance.request({
      method: 'get',
      url: 'api/statistics/list_users/',
    });
  },
};
