import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import { changeLanguage } from '../../../store/action-creators/profile';
import { resetProfileState } from '../../../store/entities/profile/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import styles from './Profile.module.scss';

const ProfileChangeLanguage = () => {
  const { i18n, t } = useTranslation();
  const [valueLanguage, setValueLanguage] = useState<any>('en');
  const dispatch = useAppDispatch();
  const profileState = useAppSelector((state) => state.profile);

  const languages = [
    {
      value: 'ru',
      label: 'Russian',
    },
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'de',
      label: 'Deutsch',
    },
    {
      value: 'es',
      label: 'Español',
    },
    {
      value: 'pt',
      label: 'Português',
    },
    { 
      value: 'id', 
      label: 'Indonesia' 
    },
    { 
      value: 'th', 
      label: 'ไทย' 
    },
    { 
      value: 'vn', 
      label: 'Tiếng việt' 
    },
  ];

  const handleChangeLanguage = () => {
    i18n.changeLanguage(valueLanguage);

    let data = {
      locale: valueLanguage,
    };

    dispatch(changeLanguage({ data: data }));
  };

  useEffect(() => {
    if (profileState.isSuccess === 'CHANGE_LANGUAGE') {
      showNotification({
        title: profileState.message,
        message: t('ProfilePage.Interface language.MessageSuccessfully'),
      });
      dispatch(resetProfileState());
    } else if (profileState.isError === 'CHANGE_LANGUAGE') {
      showNotification({
        message: profileState.message,
        color: 'red',
      });
      dispatch(resetProfileState());
    }
  }, [profileState.isError, profileState.isSuccess]);

  useEffect(() => {
    let obj = languages.find((item) => item.value === i18n.language);
    setValueLanguage(obj?.value);
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, []);

  return (
    <>
      <Title pb={15} order={3}>
        {t('ProfilePage.Interface language.Select the interface language')}
      </Title>
      <Select
        classNames={{ dropdown: styles.selectDropdown }}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        value={valueLanguage}
        data={languages}
        style={{ msOverflowStyle: 'none' }}
        onChange={setValueLanguage}
      />

      <Button mt={15} radius="md" variant="filled" onClick={() => handleChangeLanguage()}>
        {t('ProfilePage.Interface language.Save')}
      </Button>
    </>
  );
};

export default ProfileChangeLanguage;
