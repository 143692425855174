import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Button, Group, Modal, Space, Text } from "@mantine/core";
import { deleteCancelSubscription, getSubscriptionView } from "src/store/action-creators/tariff";
import { useAppDispatch } from "src/store/hooks";

export interface ITariffCancelModal {
    opened: boolean;
    setOpened: Dispatch<SetStateAction<boolean>>;
}

const TariffCancelConfirmModal: FC<ITariffCancelModal> = ({ opened, setOpened }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handlyCancelConfirm = () => {
        dispatch(deleteCancelSubscription());
        dispatch(getSubscriptionView());
        dispatch(getSubscriptionView());
        setOpened(false);
    };

    return (
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title={t('ProfilePage.Profile.Disable tariff')}
        >
            <>
                <Text
                    weight={700}>
                    {t('ProfilePage.Profile.DisableTariffNote')}
                </Text>
                <Space h="xl" />
                <Group
                    style={{ justifyContent: 'center' }}
                >
                    <Button
                        style={{ width: 150 }}
                        color="red"
                        onClick={() => handlyCancelConfirm()}
                    >{t('ProfilePage.Profile.Disable')}
                    </Button>
                    <Space w="lg" />
                    <Button
                        style={{ width: 150 }}
                        onClick={() =>
                            setOpened(false)}
                    >{t('ProfilePage.Profile.Cancel')}
                    </Button>
                </Group>
            </>
        </Modal>
    )
};
export default TariffCancelConfirmModal;