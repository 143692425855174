import { FC, FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Group,
  LoadingOverlay,
  PasswordInput,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Mail } from 'tabler-icons-react';

import { RouteNames } from '../../../routes/routes-enums';
import { login } from '../../../store/action-creators/auth';
import { getProvidersAuth } from '../../../store/action-creators/providersAuth';
import { reset, setUser } from '../../../store/entities/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import LayoutPublic from '../../../ui/LayoutPublic/LayoutPublic';

import AuthProviders from './AuthProviders';

export interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const SignIn: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, isSuccess, isError, message, token } = useAppSelector((store) => store.auth);
  const providersState = useAppSelector((store) => store.providers);
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        title: t('Login.AlertMessage.Successfully'),
        message: t('Login.AlertMessage.SuccessfulLoginMessage'),
      });
      dispatch(setUser({ token: token }));
      navigate(RouteNames.HOME);
    } else if (isError) {
      showNotification({
        title: t('Login.AlertMessage.Login error'),
        message: message,
        color: 'red',
      });
    }

    return () => {
      dispatch(reset());
    };
  }, [isSuccess, isError]);

  useEffect(() => {
    dispatch(getProvidersAuth());
  }, []);

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },

    validate: {
      email: (value) =>
        value.length > 0 ? null : t('Login.AlertMessage.Email should not be empty'),
      password: (value) =>
        value.length > 0 ? null : t('Login.AlertMessage.The password must not be empty'),
    },
  });

  const handleLogin = async (values: FormValues, event: FormEvent) => {
    event.preventDefault();
    let email = values.email;
    let password = values.password;
    await dispatch(login({ email, password }));
  };

  return (
    <LayoutPublic>
      <Container>
        <Box sx={{ maxWidth: 500 }} mx="auto">
          <LoadingOverlay visible={providersState.isLoading} overlayBlur={1} />
          <form onSubmit={form.onSubmit((values, event) => handleLogin(values, event))}>
            <TextInput
              label={t('Login.Email')}
              {...form.getInputProps('email')}
              style={{ marginTop: 15 }}
              rightSection={
                <ThemeIcon
                  variant="light"
                  size={'sm'}
                  style={{ backgroundColor: 'transparent', color: '#495057' }}
                >
                  <Mail />
                </ThemeIcon>
              }
            />
            <PasswordInput
              mt="sm"
              label={t('Login.Password')}
              {...form.getInputProps('password')}
            />
            <Group position={'apart'} align={'center'} style={{ marginTop: 20 }}>
              <Checkbox
                label={t('Login.Remember me')}
                {...form.getInputProps('rememberMe', { type: 'checkbox' })}
              />
              <Text
                variant="link"
                onClick={() => navigate(RouteNames.RESET)}
                size="sm"
                style={{ cursor: 'pointer' }}
              >
                {t('Login.Forgot your password?')}
              </Text>
            </Group>
            <Button fullWidth type="submit" style={{ marginTop: 20 }} loading={isLoading}>
              {t('Login.Enter')}
            </Button>
            <Button fullWidth variant="default" style={{ marginTop: 20 }}>
              <Link to={'/register'} style={{ color: 'inherit', textDecoration: 'none' }}>
                {t('Login.Not registered yet?')}
              </Link>
            </Button>
          </form>

          <Center mt={20}>
            <AuthProviders />
          </Center>
        </Box>
      </Container>
    </LayoutPublic>
  );
};

export default SignIn;
