import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    deleteComment,
    getThemesList,
    listTicketFields,
    listTicketsData,
    patchComment,
    patchManager,
    patchStatus,
    postStatus,
    postTheme,
    postTicket,
    postTicketManager,
    statusesList,
    viewTicketsData,
} from '../../action-creators/ticket';

// Список менеджеров
export interface IManagersListResult {
    id: number;
    username: string;
};
export interface IManagersList {
    detailed_results: Array<IManagersListResult>;
    message: string;
    result: boolean;
};

// Список статусов
export interface IStatusesListResult {
    id: number;
    order: number;
    title: string;
};
export interface IStatusesList {
    detailed_results: Array<IStatusesListResult>;
    message: string;
    result: boolean;
}

// Список тем тикетов
export interface IThemesListResult {
    id: number;
    title: string;
}
export interface IThemesList {
    detailed_results: Array<IThemesListResult>;
    message: string;
    result: boolean;
}

// Список полей модели тикет для сортировки
export interface ITicketFieldsList {
    detailed_results: Array<string>;
    message: string;
    result: boolean;
}

// Отображение списка тикетов по параметрам (по менеджеру, пользователю, статусу, рейтингу, дате создания)
export interface IPagination {
    has_next: boolean;
    has_prev: boolean;
    items_per_page: number;
    page: number;
    next_page: null | number;
    prev_page: null | number;
    sort_by: string;
    sort_order: string;
    total_items: number;
}
export interface IManagerData {
    id: number;
    email: string;
    username: string;
}
export interface ITicketStatusData {
    id: number;
    order: number;
    title: string;
}
export interface ITicketUserData {
    id: number;
    username: string;
}
export interface ITicketThemeData {
    id: number;
    title: string;
}
export interface ITicketListResults {
    id: number;
    user_data: ITicketUserData;
    status: ITicketStatusData;
    created_date: string;
    manager_data: IManagerData;
    theme: ITicketThemeData;
    description: string;
    last_view_date: string;
    last_view_user_id: number;
    comments: Array<ITicketComment>;

}
export interface ITicketDataList {
    message: string;
    pagination: IPagination;
    results: Array<ITicketListResults>;
}

// Отображение всей информации о тикете вместе с комментариями
export interface ITicketCommenterData {
    id: number;
    username: string;
}
export interface ITicketComment {
    commenter_data: ITicketCommenterData;
    content: string;
    created_date: string;
    id: number;
}
export interface ITicketUserDetail {
    id: number;
    email: string;
    username: string;
}
export interface IViewTicketsData {
    changed_date: string;
    closed_date: string;
    comments: Array<ITicketComment>;
    created_date: string;
    description: string;
    file: string;
    id: number;
    last_view_date: string;
    last_view_user_id: number;
    manager_data: IManagerData;
    status: ITicketStatusData;
    theme: ITicketThemeData;
    user_data: ITicketUserDetail;
}

//Цвет стрелок полей сортировки в таблице
export type StateButton = 'darkgray' | 'blue';

export interface IStateButtonSortBy {
    id: string;
    state: 'darkgray' | 'blue';
    order: 'asc' | 'desc';
}


export interface TicketState {
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    message: string;
    managersList: null | IManagersList;
    statusesList: null | IStatusesList;
    themesList: null | IThemesList;
    ticketFieldsList: null | ITicketFieldsList;
    ticketDataList: null | ITicketDataList;
    viewTicket: null | IViewTicketsData;
    filterManagerId: null | string;
    filterUserId: null | string;
    filterStatusId: null | string;
    fromDate: string | null;
    toDate: string | null;
    sortOrder: string;
    sortBy: string;
    activePage: number;
    stateAddedTicket: boolean;
    statePostedManager: boolean;
    statePostedStatus: boolean;
    stateAddComment: boolean;
    stateDeleteComment: boolean;
    stateButtonSortByList: Array<IStateButtonSortBy>;
    ticketDetail: number | null;
}

const initialState: TicketState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '',
    managersList: null,
    statusesList: null,
    themesList: null,
    ticketFieldsList: null,
    ticketDataList: null,
    viewTicket: null,
    filterManagerId: null,
    filterUserId: null,
    filterStatusId: null,
    fromDate: null,
    toDate: null,
    sortOrder: 'desc',
    sortBy: 'Ticket.id',
    activePage: 1,
    stateAddedTicket: false,
    statePostedManager: false,
    statePostedStatus: false,
    stateAddComment: false,
    stateDeleteComment: false,
    stateButtonSortByList: [],
    ticketDetail: null,
};

export const ticketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        resetTicketState: () => initialState,
        setFilterManagerId: (state, action: PayloadAction<{ id: string | null }>) => {
            state.filterManagerId = action.payload.id;
            state.activePage = 1;
        },
        setFilterUserId: (state, action: PayloadAction<{ id: string | null }>) => {
            state.filterUserId = action.payload.id;
            state.activePage = 1;
        },
        setFilterStatusId: (state, action: PayloadAction<{ id: string | null }>) => {
            state.filterStatusId = action.payload.id;
            state.activePage = 1;
        },
        setFromDate: (state, action: PayloadAction<{ from_date: string | null }>) => {
            state.fromDate = action.payload.from_date;
        },
        setToDate: (state, action: PayloadAction<{ to_date: string | null }>) => {
            state.toDate = action.payload.to_date;
        },
        setSortOrder: (state, action: PayloadAction<{ order: string }>) => {
            state.sortOrder = action.payload.order;
        },
        setSortBy: (state, action: PayloadAction<{ id: string }>) => {
            state.sortBy = action.payload.id;
        },
        setActivePage: (state, action: PayloadAction<{ page: number }>) => {
            state.activePage = action.payload.page;
        },
        setStateButtonSortBy: (state, action: PayloadAction<{ id: string }>) => {
            const index = state.stateButtonSortByList.findIndex((value) => value.id === action.payload.id);
            state.stateButtonSortByList[index].state = 'blue';
        },
        setStateButtonSortOrder: (state, action: PayloadAction<{ id: string, order: 'desc' | 'asc' }>) => {
            const index = state.stateButtonSortByList.findIndex((value) => value.id === action.payload.id);
            state.stateButtonSortByList[index].order = action.payload.order;
        },
        setStateButtonSortByList: (state, action: PayloadAction<{ arr: Array<IStateButtonSortBy> }>) => {
            state.stateButtonSortByList = action.payload.arr;
        },
        setStateButtonSortByListReset: (state) => {
            for (let index = 0; index < state.stateButtonSortByList.length; index++) {
                state.stateButtonSortByList[index].state = 'darkgray';
            }
        },
        setResetComment: (state) => {
            state.stateAddComment = false;
            state.stateDeleteComment = false;
        },
        setticketDetailId: (state, action: PayloadAction<number>) => {
            state.ticketDetail = action.payload;
        },
    },

    extraReducers: {
        //Выбор менеджера
        [patchManager.pending.type]: (state) => {
            state.isLoading = true;
        },
        [patchManager.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.statePostedManager = state.statePostedManager ? false : true;
        },
        [patchManager.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        //Выбор статуса
        [patchStatus.pending.type]: (state) => {
            state.isLoading = true;
        },
        [patchStatus.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.statePostedStatus = state.statePostedStatus ? false : true;
        },
        [patchStatus.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        //Список статусов тикета
        [statusesList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [statusesList.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.statusesList = action.payload;
        },
        [statusesList.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        //Список тем тикета
        [getThemesList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getThemesList.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.themesList = action.payload;
        },
        [getThemesList.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Список полей модели тикет для сортировки
        [listTicketFields.pending.type]: (state) => {
            state.isLoading = true;
        },
        [listTicketFields.fulfilled.type]: (state, action: PayloadAction<ITicketFieldsList>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ticketFieldsList = action.payload;
        },
        [listTicketFields.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Добавление статуса
        [postStatus.pending.type]: (state) => {
            state.isLoading = true;
        },
        [postStatus.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = 'Успех';
        },
        [postStatus.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Добавление темы
        [postTheme.pending.type]: (state) => {
            state.isLoading = true;
        },
        [postTheme.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = 'Успех';
        },
        [postTheme.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Добавление тикета
        [postTicket.pending.type]: (state) => {
            state.isLoading = true;
        },
        [postTicket.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = 'Тикет добавлен';
            state.stateAddedTicket = state.stateAddedTicket ? false : true;
        },
        [postTicket.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Добавление тикета мереджером
        [postTicketManager.pending.type]: (state) => {
            state.isLoading = true;
        },
        [postTicketManager.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = 'Тикет добавлен';
            state.stateAddedTicket = state.stateAddedTicket ? false : true;
        },
        [postTicketManager.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Отображение списка тикетов по параметрам (по менеджеру, пользователю, статусу, рейтингу, дате создания)
        [listTicketsData.pending.type]: (state) => {
            state.isLoading = true;
        },
        [listTicketsData.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            // state.message = message;
            state.ticketDataList = action.payload;
        },
        [listTicketsData.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Отображение всей информации о тикете вместе с комментариями
        [viewTicketsData.pending.type]: (state) => {
            state.isLoading = true;
        },
        [viewTicketsData.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = 'Успех';
            state.viewTicket = action.payload;
        },
        [viewTicketsData.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Добавить комментарий
        [patchComment.pending.type]: (state) => {
            state.isLoading = true;
        },
        [patchComment.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = 'Успех';
            state.stateAddComment = true;
        },
        [patchComment.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
        // Удалить комментарий
        [deleteComment.fulfilled.type]: (state, action) => {
            state.isError = false;
            state.isSuccess = true;
            state.stateDeleteComment = true;
            state.message = action.payload.message;
        },
        [deleteComment.rejected.type]: (state, action) => {
            state.isError = true;
            state.isSuccess = false;
            state.message = action.payload.message || 'Внезапная ошибка';
        },
    }
});

export const {
    resetTicketState,
    setFilterManagerId,
    setFilterStatusId,
    setFilterUserId,
    setFromDate,
    setToDate,
    setSortOrder,
    setSortBy,
    setActivePage,
    setStateButtonSortByList,
    setStateButtonSortBy,
    setStateButtonSortByListReset,
    setResetComment,
    setStateButtonSortOrder,
    setticketDetailId,
} = ticketSlice.actions;

export default ticketSlice.reducer;
