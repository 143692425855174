import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { clsx, Group, Text } from '@mantine/core';

import styles from './LayoutNavbarButton.module.scss';

interface ILayoutNavbarButton {
  opened?: boolean;
  label: string;
  icon?: ReactNode;
  href: string;
}

const LayoutNavbarButton: FC<ILayoutNavbarButton> = ({ opened = true, label, icon, href }) => {
  return (
    <NavLink
      to={href}
      className={({ isActive }: any) => clsx(styles.root, isActive ? styles.rootActive : '')}
    >
      <Group spacing={10} noWrap>
        {icon}
        {opened && (
          <Text className={styles.text} lineClamp={1}>
            {label}
          </Text>
        )}
      </Group>
    </NavLink>
  );
};

export default LayoutNavbarButton;
