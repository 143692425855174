import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Group, Space, Text, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
// import { getSubscriptionView } from 'src/store/action-creators/tariff';
// import { ISubscriptionView } from 'src/store/entities/billing/tariffSlice';
import { Calendar, Coin, CoinOff, Diamonds, Eye, EyeOff, Logout, Mail, News, NewsOff, Phone, User } from 'tabler-icons-react';

import { RouteNames } from '../../../routes/routes-enums';
import { logoutUserAction, profile } from '../../../store/action-creators/auth';
import { getProvidersAuth } from '../../../store/action-creators/providersAuth';
import { logoutUser } from '../../../store/entities/auth/authSlice';
import { resetProvidersAuthState } from '../../../store/entities/auth/providerAuthSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IProviders } from '../../../types/IProviders';
import { getProvidersProfile, providerExist } from '../../../utils/getExistProviders';

import ProviderCollapse from './components/ProviderCollapse/ProviderCollapse';
import RenewSubscriptionToggle from './ProfileTariff/RenewSubscriptionToggle';
import TariffCancelSubscription from './ProfileTariff/TariffCancelSubscription';

const ProfileInfo = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((store) => store.auth);
  const { viewSubscription } = useAppSelector((state) => state.tariff)
  const providersState = useAppSelector((state) => state.providers);
  const [providers, setProviders] = useState<IProviders[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    const token = JSON.parse(localStorage.getItem('userToken') || '{}')?.token;
    dispatch(logoutUserAction(token));
    dispatch(logoutUser());
    navigate(RouteNames.LOGIN);
  };

  const formatDate = (value: string) => {
    const parsedDateMs = Date.parse(value);
    const date = new Date(parsedDateMs).toLocaleString('ru-Ru');
    return date;
  };
  const expiredSubscriptionDate = () => {
    if (!viewSubscription?.finish_date) {
      return '-';
    } else return formatDate(viewSubscription?.finish_date!);
  };

  // useEffect(() => {
  //   dispatch(getSubscriptionView());
  // }, []);

  useEffect(() => {
    if (providersState.isSuccess && user) {
      let providersExist = providerExist(providersState.providers);
      let arr = getProvidersProfile(user, providersExist, true);
      setProviders(arr);
      dispatch(resetProvidersAuthState());
    }
  }, [providersState.isSuccess]);

  useEffect(() => {
    if (providersState.isSuccessUnlink) {
      showNotification({
        message: providersState.message,
      });
      dispatch(resetProvidersAuthState());
      dispatch(getProvidersAuth());
      dispatch(profile());
      navigate(RouteNames.PROFILE);
    } else if (providersState.isErrorUnlink) {
      showNotification({
        message: providersState.message,
        color: 'red',
      });
    }
  }, [providersState.isSuccessUnlink, providersState.isErrorUnlink]);

  return (
    <>
      <Group pb={20}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {<Calendar size={22} />} <Space w="md" />
          <Text>{t('ProfilePage.Profile.Account created')}:</Text>
        </div>
      </Group>
      <Group pb={20}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {<User size={22} />} <Space w="md" />
          <Text>
            {t('ProfilePage.Profile.Name')}: {user?.username}
          </Text>
        </div>
      </Group>
      <Group pb={20}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {<Phone size={22} />} <Space w="md" />
          <Text>{t('ProfilePage.Profile.Phone')}</Text>
          <Space w="md" />
          <Text>{user?.phone_number}</Text>
        </div>
      </Group>
      <Group pb={20}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {<Mail size={22} />}
          <Space w="md" />
          <Text>{user?.email}</Text>
        </div>
      </Group>
      <Group pb={20}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {<Diamonds size={22} />} <Space w="md" />
          <Text>{<b>{viewSubscription?.plan.title}</b>}</Text>
          <Space w="xs" />
          <Tooltip
            label={t('ProfilePage.Profile.NewsPublication')}
            color="dark"
            withArrow
          >
            <Text size="sm" color="dimmed">
              {`${viewSubscription?.plan.restrictions['Publish news']}` === `true` ?
                <News color='#228be6' /> : <NewsOff />}
            </Text>
          </Tooltip>
          <Space w="xs" />
          <Tooltip
            label={t('ProfilePage.Profile.NewsReading')}
            color="dark"
            withArrow
          >
            <Text size="sm" color="dimmed">
              {`${viewSubscription?.plan.restrictions['View news']}` === `true` ?
                <Eye color='#228be6' /> : <EyeOff />}
            </Text>
          </Tooltip>
        </div>
      </Group>
      <Group pb={20}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {viewSubscription?.tariff_plan_id ? <Coin size={22} /> : <CoinOff size={22} />}
          <Space w="md" />
          <Text>{t('ProfilePage.Profile.YourTariff')}: </Text>
          <Space w="xs" />
          <Text>{<b>{viewSubscription?.plan.description}</b>}</Text>
        </div>
      </Group>
      <Group pb={20}>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
          {/* {<Coin size={22} />} */}
          <Space w="xs" />
          <Text>{t('ProfilePage.Profile.ActiveUntil')}</Text>
          <Space w="xs" />
          <Text style={{ color: 'red' }}>{expiredSubscriptionDate()}</Text>
          <Space w="xs" />
          <TariffCancelSubscription />
        </div>
      </Group>
      <Group pb={20}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RenewSubscriptionToggle />
        </div>
      </Group>
      {providers.map((provider) => (
        <Group pb={20}>
          <div>
            <ProviderCollapse provider={provider} />
          </div>
        </Group>
      ))}

      <Group>
        <Button
          leftIcon={<Logout size={22} />}
          radius="md"
          variant="default"
          color="gray"
          onClick={() => handleLogout()}
        >
          {t('ProfilePage.Profile.Log out of the system')}
        </Button>
      </Group>
    </>
  );
};

export default ProfileInfo;
