import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, Footer, Group, Text } from '@mantine/core';

import { de, es, gb, id, pt, ru, th, vn } from '../../../../assets/images';

const useStyles = createStyles((theme) => ({
  textFooter: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : '#595858',
  },
}));

const LayoutFooter: FC = () => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const languagesItem = [
    { id: 'en', img: gb, label: 'English' },
    {
      id: 'ru',
      img: ru,
      label: 'Русский',
    },
    { id: 'de', img: de, label: 'Deutsch' },
    { id: 'es', img: es, label: 'Español' },
    { id: 'pt', img: pt, label: 'Português' },
    { id: 'id', img: id, label: 'Indonesia' },
    { id: 'th', img: th, label: 'ไทย' },
    { id: 'vn', img: vn, label: 'Tiếng việt' },
  ];

  return (
    <Footer height={'auto'} p={'md'} style={{ position: 'absolute', bottom: 0, width: '100%' }}>
      <Box sx={{ maxWidth: 600 }} mx="auto">
        <Group position="center" spacing={'xl'}>
          <Text className={classes.textFooter} size="sm" component="a" href="">
            {t('Offer')}
          </Text>
          <Text className={classes.textFooter} size="sm" component="a" href="">
            {t('Privacy policy')}
          </Text>
          <Text className={classes.textFooter} size="sm" component="a" href="">
            {t('About the service')}
          </Text>
        </Group>
        <Group position="center" spacing={'xs'} style={{ marginTop: 5 }}>
          {languagesItem.map((item) => (
            <Box key={item.label} style={{ marginLeft: 10, marginRight: 10 }}>
              <img src={item.img} alt="" width={20} height={12} />
              &nbsp;
              <Text
                className={classes.textFooter}
                size="sm"
                component="a"
                onClick={() => changeLanguage(item.id)}
                style={{ cursor: 'pointer', marginLeft: 5 }}
              >
                {item.label}
              </Text>
            </Box>
          ))}
        </Group>
      </Box>
    </Footer>
  );
};

export default LayoutFooter;
