import axiosInstance from "./axiosInstance";

export interface ListPurchasesQuery {
    page: number;
    items_per_page?: number;
    sort_by?: string;
    sort_order?: string;
}

export interface MakePurchaseData {
    slug: string;
    // Enum: Array<string>;
}

export interface SetAutoRenewalData {
    auto_renewal: boolean;
}

export interface IAutoRenewalDataResponce {
    mesage: string;
    result: boolean;
}

// Список тарифных планов
export const getListPlansFetch = async () => {
    const response = await axiosInstance.get('/api/billing/plan/list-plans/');
    return response;
}

// Список купленных пользователем тарифов
export const getListPurchasesFetch = async (data: ListPurchasesQuery) => {
    const response = await axiosInstance.get(`/api/billing/purchase/list-purchases/${data.page}/`, {
        params: {
            ...data
        }
    });
    return response;
}

// Купить подписку
export const postMakePurchaseFetch = async ({ slug }: MakePurchaseData) => {
    const response = await axiosInstance.post('/api/billing/purchase/make-purchase/', {
        slug
    })
    return response;
}

// Отменить подписку
export const deleteCancelSubscriptionFetch = async () => {
    const response = await axiosInstance.delete(`/api/billing/subscription/cancel-subscription/`);
    return response;
}

// Включить/выключить автообновление подписки
export const patchSetAutoRenewalFetch = async ({ auto_renewal }: SetAutoRenewalData) => {
    const response = await axiosInstance.patch(`/api/billing/subscription/set-auto-renewal/`, {
        auto_renewal
    })
    return response;
}

// Просмотр текущей подписки
export const getSubscriptionViewFetch = async () => {
    const response = await axiosInstance.get('/api/billing/subscription/subscription-view/');
    return response;
}