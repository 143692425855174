import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clsx, Group, Header } from '@mantine/core';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { RouteNames } from 'src/routes/routes-enums';
import { logoutUserAction } from 'src/store/action-creators/auth';
import { logOutUserEmulation } from 'src/store/action-creators/users';
import { logoutUser, setUser } from 'src/store/entities/auth/authSlice';
import { resetLogoutEmulateState } from 'src/store/entities/users/usersSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { ArrowLeft } from 'tabler-icons-react';

import NotificationsPopover from './components/NotificationsPopover/NotificationsPopover';
import ProfileMenu from './components/ProfileMenu/ProfileMenu';
import SupportOverlay from './components/SupportOverlay/SupportOverlay';

import styles from './LayoutHeader.module.scss';

interface ILayoutHeader {
  title?: string;
  back?: string;
}

const LayoutHeader: FC<ILayoutHeader> = ({ title, back }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { message, isErrorLogoutEmulate, isSuccessLogoutEmulate } = useAppSelector(
    (store) => store.users
  );

  const handleLogout = () => {
    const token = JSON.parse(localStorage.getItem('userToken') || '{}')?.token;
    dispatch(logoutUser());
    dispatch(logoutUserAction({ token }));
    cleanNotifications();
    navigate(RouteNames.LOGIN);
  };

  const logoutEmulation = () => {
    dispatch(logOutUserEmulation());
  };

  const handleProfile = () => {
    navigate(RouteNames.PROFILE, { state: { tabName: 'profile' } });
  };

  const handleTariff = () => {
    navigate(RouteNames.PROFILE, { state: { tabName: 'tariff' } });
  };

  const handleLanguages = () => {
    navigate(RouteNames.PROFILE, { state: { tabName: 'language' } });
  };

  useEffect(() => {
    if (isSuccessLogoutEmulate) {
      showNotification({
        message: message,
      });
      let token = JSON.parse(localStorage.getItem('userToken') || '{}').token;
      dispatch(setUser({ token }));
      dispatch(resetLogoutEmulateState());
      location.reload();
    } else if (isErrorLogoutEmulate) {
      showNotification({
        message: message,
        color: 'red',
      });
      dispatch(resetLogoutEmulateState());
    }
  }, [isErrorLogoutEmulate, isSuccessLogoutEmulate]);

  return (
    <Header className={styles.header} height={60} py="xs" px={16}>
      {(title || back) && (
        <div
          className={clsx(styles.title, back && styles.titleBack)}
          onClick={back ? () => navigate(back) : undefined}
        >
          {back && <ArrowLeft size={24} />}
          {title || t('Back')}
        </div>
      )}

      <Group ml="auto">
        <SupportOverlay />
        <NotificationsPopover />
        <ProfileMenu
          handleLogout={handleLogout}
          logoutEmulation={logoutEmulation}
          handleProfile={handleProfile}
          handleTariff={handleTariff}
          handleLanguages={handleLanguages}
        />
      </Group>
    </Header>
  );
};

export default LayoutHeader;
