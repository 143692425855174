import axiosInstance from './axiosInstance';

export interface GetUsersData {
  page: number;
  sortType: 'asc' | 'desc';
  itemsPerPage: number;
  providerAuth?: 'vk' | 'yandex';
  status?: boolean;
  role?: 'operator' | 'user' | 'superuser' | 'admin';
  sortBy?: 'id' | 'email' | 'username';
  isDeleted?: boolean;
}

export interface AddUserData {
  email: string;
  password: string;
  phone_number: string;
  role: string | null;
  username: string;
}

export interface DeleteUserData {
  id: number;
}

export interface LogInToUserEmulationData {
  id: number;
}

export interface UpdateStatusUserData {
  id: number;
  status: boolean;
}

export interface EditUserData {
  id: number | null;
  email: string;
  phoneNumber: string;
  role: string | null;
  username: string;
}

export const getUsersFetch = async ({
  page,
  sortType,
  itemsPerPage,
  providerAuth,
  sortBy,
  status,
  role,
  isDeleted,
}: GetUsersData) => {
  const response = await axiosInstance.get('/api/users/users/' + page + '/', {
    params: {
      sort_type: sortType,
      items_per_page: itemsPerPage,
      provider_auth: providerAuth,
      sort_by: sortBy,
      status: status,
      role: role,
      is_deleted: isDeleted,
    },
  });
  return response;
};

export const deleteUserFetch = async ({ id }: DeleteUserData) => {
  const response = await axiosInstance.delete(`/api/users/user/${id}/`);
  return response;
};

export const updateStatusUserFetch = async ({ id, status }: UpdateStatusUserData) => {
  const response = await axiosInstance.patch(`/api/users/user/${id}/?status=${status}`);
  return response;
};

export const editUserFetch = async ({ id, email, phoneNumber, role, username }: EditUserData) => {
  const response = await axiosInstance.post(`/api/users/user/${id}/`, {
    email: email,
    phone_number: phoneNumber,
    role: role,
    username: username,
  });
  return response;
};

export const logInToUserEmulationFetch = async ({ id }: LogInToUserEmulationData) => {
  const response = await axiosInstance.post(`/api/users/login/${id}/`);
  return response;
};

export const logOutUserEmulationFetch = async () => {
  const response = await axiosInstance.get(`/api/users/logout/`);
  return response;
};

export const getUserFetch = async ({ id }: { id: number }) => {
  const response = await axiosInstance.get(`/api/users/user/${id}`);
  return response;
};

export const addUserFetch = async ({
  email,
  password,
  phone_number,
  role,
  username,
}: AddUserData) => {
  const response = await axiosInstance.post(`/api/users/user/`, {
    email: email,
    password: password,
    phone_number: phone_number,
    role: role,
    username: username,
  });
  return response;
};

export const confirmEmailFetch = async ({ id }: { id: number }) => {
  const response = await axiosInstance.get(`/api/users/confirm_user/${id}/`);
  return response;
};
