import axiosInstance from './axiosInstance';

export interface NotificationStatusData {
  status: boolean;
}

export interface DeleteTelegramAccountData {
  nickname: string;
}

export const getUrlTelegramFetch = async () => {
  const response = await axiosInstance.get('/api/telegram/url/');
  return response;
};

export const getQRTelegramFetch = async () => {
  const response = await axiosInstance.get('/api/telegram/qr/');
  return response;
};
export const setTelegramNotificationFetch = async ({ status }: NotificationStatusData) => {
  const response = await axiosInstance.patch('/api/telegram/notifications/?set_type=' + status);
  return response;
};

export const getTelegramAccountsFetch = async () => {
  const response = await axiosInstance.get('/api/telegram/account/');
  return response;
};

export const deleteTelegramAccountFetch = async ({ nickname }: DeleteTelegramAccountData) => {
  const response = await axiosInstance.delete('/api/telegram/account/', {
    data: { nickname: nickname },
  });
  return response;
};
