import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Space, Text, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import { resetPasswordUser } from '../../../api/authApi';
import { useAppSelector } from '../../../store/hooks';

const ProfileChangePassword = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((store) => store.auth);
  const [isMessageSend, setIsMessageSend] = useState(false);

  const handleSend = async () => {
    setIsMessageSend(true);
    if (!user?.email) {
      showNotification({
        title: t('ProfilePage.Change Password.SentMail.SentMailFail'),
        message: t('ProfilePage.Change Password.SentMail.SentMailFailMessage'),
        color: 'red',
      });
      return;
    }
    const response = await resetPasswordUser({ email: user.email });
    setIsMessageSend(false);
    if (response.status === 200) {
      showNotification({
        title: t('ProfilePage.Change Password.SentMailSuccess.Successfully'),
        message: t(
          'ProfilePage.Change Password.SentMailSuccess.An email has been sent to you with instructions on changing your password'
        ),
        color: 'blue',
      });
    } else {
      showNotification({
        title: t('ProfilePage.Change Password.SentMailFail.Error'),
        message:
          t('ProfilePage.Change Password.SentMailFail.ErrorMessage.Server error') +
          response.status +
          t(
            "ProfilePage.Change Password.SentMailFail.ErrorMessage.we're already dealing with this! Try again later"
          ),
        color: 'red',
      });
    }
  };

  return (
    <>
      <Title order={3} pb={15}>
        {t('ProfilePage.Change Password.Password change')}
      </Title>
      <Button
        mb={15}
        radius="md"
        variant="filled"
        loading={isMessageSend}
        onClick={() => handleSend()}
      >
        {t('ProfilePage.Change Password.Send an email with instructions')}
      </Button>
      <Group>
        <div style={{ display: 'flex' }}>
          <Text>{t('ProfilePage.Change Password.SentMail.We will send you to')}</Text>
          <Space w="xs" />
          <Text weight={700}>{user?.email}</Text>
          <Space w="xs" />
          <Text>
            {t(
              'ProfilePage.Change Password.SentMail.a letter with instructions on how to change your password'
            )}
          </Text>
        </div>
      </Group>
    </>
  );
};

export default ProfileChangePassword;
