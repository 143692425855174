import axios from 'axios';

import { getAuthToken } from '../utils/localStorage';

import axiosInstance, { BASE_URL } from './axiosInstance';

export interface IGetTokenProvider {
  providerName?: string | null;
  code: string | null;
}

export const getProvidersAuthFetch = async () => {
  const response = await axios.get(BASE_URL + '/api/auth/providers');
  return response;
};

export const getTokenProviderFetch = async ({ providerName, code }: IGetTokenProvider) => {
  const token = getAuthToken();
  let response;
  if (token) {
    response = await axiosInstance.get(
      BASE_URL + `/api/auth/callback/${providerName}/?code=${code}`
    );
  } else {
    response = await axios.get(BASE_URL + `/api/auth/callback/${providerName}/?code=${code}`);
  }
  return response;
};

export const unlinkProviderFetch = async ({ providerName }: { providerName: string }) => {
  const response = await axiosInstance.get(`/api/auth/unlink_provider/${providerName}/`);
  return response;
};
