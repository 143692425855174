import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, Group, Text } from '@mantine/core';

import { unlinkProvider } from '../../../../../store/action-creators/providersAuth';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

interface YandexCollapseProps {
  opened: boolean;
}

const YandexCollapse: FC<YandexCollapseProps> = ({ opened }) => {
  const { user } = useAppSelector((store) => store.auth);
  const provider = useAppSelector((store) => store.providers);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  if (!user?.yandex_user_data) {
    return <></>;
  }

  return (
    <Collapse in={opened}>
      {user.yandex_user_data.default_email && (
        <Group spacing={'xs'} mt={15}>
          <Text>{t('ProfilePage.Profile.ProviderYandexEmail')}:</Text>
          <Text>{user.yandex_user_data.default_email}</Text>
        </Group>
      )}
      {user.yandex_user_data.real_name && (
        <Group spacing={'xs'} mt={15}>
          <Text>{t('ProfilePage.Profile.ProviderYandexFullName')}:</Text>
          <Text>{user.yandex_user_data.real_name}</Text>
        </Group>
      )}
      {user.yandex_user_data.username && (
        <Group spacing={'xs'} mt={15}>
          <Text>{t('ProfilePage.Profile.ProviderYandexUsername')}:</Text>
          <Text>{user.yandex_user_data.username}</Text>
        </Group>
      )}
      {user.yandex_user_data.sex && (
        <Group spacing={'xs'} mt={15}>
          <Text>{t('ProfilePage.Profile.ProviderYandexSex')}:</Text>
          <Text>{user.yandex_user_data.sex}</Text>
        </Group>
      )}
      <Button
        size={'xs'}
        color={'red'}
        mt={15}
        onClick={() => dispatch(unlinkProvider({ providerName: 'yandex' }))}
        loading={provider.isLoadingUnlink}
      >
        {t('ProfilePage.Profile.ProviderUnlinkAccount')}
      </Button>
    </Collapse>
  );
};

export default YandexCollapse;
