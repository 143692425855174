import { createSlice } from '@reduxjs/toolkit';

import {
  getProvidersAuth,
  getTokenProvider,
  unlinkProvider,
} from '../../action-creators/providersAuth';

import { IProvidersAuthState } from './types';

const initialState: IProvidersAuthState = {
  isLoading: false,
  isSuccess: false,
  isError: false,

  isLoadingUnlink: false,
  isSuccessUnlink: false,
  isErrorUnlink: false,

  providers: [],
  message: null,
  providerToken: null,
};

export const providersAuthSlice = createSlice({
  name: 'providersAuth',
  initialState,
  reducers: {
    resetProvidersAuthState: () => initialState,
  },

  extraReducers: {
    // Получить провайдеров
    [getProvidersAuth.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getProvidersAuth.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.providers = action.payload;
    },
    [getProvidersAuth.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || 'Внезапная ошибка';
    },

    // получить токен через провайдера
    [getTokenProvider.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getTokenProvider.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.providerToken = action.payload.auth_token;
    },
    [getTokenProvider.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message || 'Внезапная ошибка';
    },

    // отвязать провайдера

    [unlinkProvider.pending.type]: (state) => {
      state.isLoadingUnlink = true;
    },
    [unlinkProvider.fulfilled.type]: (state, action) => {
      state.isLoadingUnlink = false;
      state.isSuccessUnlink = true;
      state.isErrorUnlink = false;
      state.message = action.payload.message;
    },
    [unlinkProvider.rejected.type]: (state, action) => {
      state.isSuccessUnlink = false;
      state.isLoadingUnlink = false;
      state.isErrorUnlink = true;
      state.message = action.payload.message || 'Внезапная ошибка';
    },
  },
});

export const { resetProvidersAuthState } = providersAuthSlice.actions;
export default providersAuthSlice.reducer;
