import { IProfileEditData } from '../types/IProfile';

import axiosInstance from './axiosInstance';

export const uploadAvatarFetch = async ({ file }: { file: File }) => {
  let formData = new FormData();
  formData.append('image', file);
  const response = await axiosInstance.post('/api/users/profile/avatar/', formData);
  return response;
};

export const editProfileFetch = async ({ data }: { data: IProfileEditData }) => {
  const response = await axiosInstance.post('/api/users/profile/', data);
  return response;
};
