import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  GetHelpCardsData,
  getHelpCardsFetch,
  GetHelpCategoriesData,
  getHelpCategoriesFetch,
  GetHelpTagsData,
  getHelpTagsFetch,
} from '../../api/helpApi';

export const getHelpCards = createAsyncThunk('help/getHelpCards', async (arg: GetHelpCardsData, thunkAPI) => {
  try {
    const response = await getHelpCardsFetch(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const getHelpCategories = createAsyncThunk('help/getHelpCategories', async (arg: GetHelpCategoriesData, thunkAPI) => {
  try {
    const response = await getHelpCategoriesFetch(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});

export const getHelpTags = createAsyncThunk('help/getHelpTags', async (arg: GetHelpTagsData, thunkAPI) => {
  try {
    const response = await getHelpTagsFetch(arg);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.response?.statusText);
    }
  }
});
