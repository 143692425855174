import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { editProfileFetch, uploadAvatarFetch } from '../../api/profileApi';
import { IProfileEditData } from '../../types/IProfile';

export const uploadAvatar = createAsyncThunk(
  'profile/upload_avatar',
  async (arg: { file: File }, thunkAPI) => {
    try {
      const response = await uploadAvatarFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const editProfile = createAsyncThunk(
  'profile/edit_profile',
  async (arg: { data: IProfileEditData }, thunkAPI) => {
    try {
      const response = await editProfileFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);

export const changeLanguage = createAsyncThunk(
  'profile/change_language',
  async (arg: { data: IProfileEditData }, thunkAPI) => {
    try {
      const response = await editProfileFetch(arg);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error.response?.statusText);
      }
    }
  }
);
