import { FC, useState } from 'react';
import { Group } from '@mantine/core';
import { CaretRight } from 'tabler-icons-react';

import { IProviders } from '../../../../../types/IProviders';

import VkCollapse from './VkCollapse';
import YandexCollapse from './YandexCollapse';

import styles from './ProviderCollapse.module.scss';

interface ProviderCollapseProps {
  provider: IProviders;
}

const ProviderCollapse: FC<ProviderCollapseProps> = ({ provider }) => {
  const [opened, setOpened] = useState(true);

  return (
    <>
      <div>
        <Group spacing={'xs'}>
          <CaretRight
            className={opened ? styles.active : styles.arrow}
            strokeWidth={1.5}
            size={20}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpened(!opened)}
          />
          {<img src={provider.logo} alt="" width={25} />}
        </Group>
      </div>
      {provider.providerName === 'yandex' && (
        <div className={styles.collapse}>
          <YandexCollapse opened={opened} />
        </div>
      )}
      {provider.providerName === 'vk' && (
        <div className={styles.collapse}>
          <VkCollapse opened={opened} />
        </div>
      )}
    </>
  );
};

export default ProviderCollapse;
