import { FC, useEffect, useState } from 'react';
import { Loader } from '@mantine/core';

import { resetProvidersAuthState } from '../../../../../store/entities/auth/providerAuthSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { IProviders } from '../../../../../types/IProviders';
import Providers from '../../../../../ui/Providers/Providers';
import { getProvidersProfile, providerExist } from '../../../../../utils/getExistProviders';

interface SettingsProvidersProps {
  isConnect?: boolean;
}

const SettingsProviders: FC<SettingsProvidersProps> = ({ isConnect = false }) => {
  const profile = useAppSelector((state) => state.auth.user);
  const providersState = useAppSelector((state) => state.providers);
  const dispatch = useAppDispatch();
  const [providers, setProviders] = useState<IProviders[]>([]);

  useEffect(() => {
    if (providersState.isSuccess && profile) {
      let providersExist = providerExist(providersState.providers);
      let arr = getProvidersProfile(profile, providersExist, isConnect);
      setProviders(arr);
      dispatch(resetProvidersAuthState());
    }
  }, [providersState.isSuccess]);

  return (
    <>
      {providersState.isLoading ? (
        <Loader size={30} />
      ) : (
        <Providers providers={providers} iconSize={25} />
      )}
    </>
  );
};

export default SettingsProviders;
