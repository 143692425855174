import { createSlice } from '@reduxjs/toolkit';

import {
  addUser,
  confirmEmail,
  deleteUser,
  editUser,
  getUser,
  getUsers,
  logInToUserEmulation,
  logOutUserEmulation,
  updateStatusUser,
} from '../../action-creators/users';
import { IUsersList } from '../../types/IUsers';

export interface UsersState {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;

  isLoadingEmulate: boolean;
  isErrorEmulate: boolean;
  isSuccessEmulate: boolean;

  isLoadingLogoutEmulate: boolean;
  isErrorLogoutEmulate: boolean;
  isSuccessLogoutEmulate: boolean;

  isLoadingDelete: boolean;
  isErrorDelete: boolean;
  isSuccessDelete: boolean;

  isLoadingUpdateStatus: boolean;
  isErrorUpdateStatus: boolean;
  isSuccessUpdateStatus: boolean;

  isLoadingEdit: boolean;
  isErrorEdit: boolean;
  isSuccessEdit: boolean;

  isLoadingGetUser: boolean;
  isErrorGetUser: boolean;
  isSuccessGetUser: boolean;

  isLoadingAddUser: boolean;
  isErrorAddUser: boolean;
  isSuccessAddUser: boolean;

  isLoadingConfirmEmail: boolean;
  isErrorConfirmEmail: boolean;
  isSuccessConfirmEmail: boolean;

  addUserError: {
    email: null | string[];
    password: null | string[];
    role: null | string[];
    username: null | string[];
  };

  countUsers: number;
  message: any;
  users: IUsersList[];
  emulateToken: string | null;

  editUserError: {
    email: null | string[];
    username: null | string[];
    _schema: null | string[];
    phone: null | string[];
    role: null | string[];
  };
}

const initialState: UsersState = {
  isLoading: false,
  isError: false,
  isSuccess: false,

  isLoadingEmulate: false,
  isErrorEmulate: false,
  isSuccessEmulate: false,

  isLoadingLogoutEmulate: false,
  isErrorLogoutEmulate: false,
  isSuccessLogoutEmulate: false,

  isLoadingDelete: false,
  isErrorDelete: false,
  isSuccessDelete: false,

  isLoadingUpdateStatus: false,
  isErrorUpdateStatus: false,
  isSuccessUpdateStatus: false,

  isLoadingEdit: false,
  isErrorEdit: false,
  isSuccessEdit: false,

  isLoadingGetUser: false,
  isErrorGetUser: false,
  isSuccessGetUser: false,

  isLoadingConfirmEmail: false,
  isErrorConfirmEmail: false,
  isSuccessConfirmEmail: false,

  isLoadingAddUser: false,
  isErrorAddUser: false,
  isSuccessAddUser: false,
  addUserError: {
    email: null,
    password: null,
    role: null,
    username: null,
  },

  message: null,
  users: [],
  countUsers: 0,
  emulateToken: null,
  editUserError: {
    email: null,
    username: null,
    _schema: null,
    phone: null,
    role: null,
  },
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsersState: () => initialState,
    resetDeleteUserState: (state) => {
      state.isLoadingDelete = false;
      state.isErrorDelete = false;
      state.isSuccessDelete = false;
    },
    resetUpdateUserState: (state) => {
      state.isLoadingUpdateStatus = false;
      state.isErrorUpdateStatus = false;
      state.isSuccessUpdateStatus = false;
    },
    resetEditUserState: (state) => {
      state.isLoadingEdit = false;
      state.isErrorEdit = false;
      state.isSuccessEdit = false;
      state.editUserError = {
        email: null,
        username: null,
        _schema: null,
        phone: null,
        role: null,
      };
    },
    resetEmulateUserState: (state) => {
      state.isSuccessEmulate = false;
      state.isSuccessEmulate = false;
      state.isSuccessEmulate = false;
    },
    resetLogoutEmulateState: (state) => {
      state.isLoadingLogoutEmulate = false;
      state.isErrorLogoutEmulate = false;
      state.isSuccessLogoutEmulate = false;
    },
    resetGetUserState: (state) => {
      state.isErrorGetUser = false;
      state.isSuccessGetUser = false;
      state.isLoadingGetUser = false;
    },
    resetAddUserState: (state) => {
      state.isLoadingAddUser = false;
      state.isErrorAddUser = false;
      state.isSuccessAddUser = false;
      state.addUserError = initialState.addUserError;
    },

    resetConfirmEmail: (state) => {
      state.isSuccessConfirmEmail = false;
      state.isErrorConfirmEmail = false;
      state.isLoadingConfirmEmail = false;
    },
  },
  extraReducers: {
    // получение пользователей
    [getUsers.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.users = action.payload.users;
      state.countUsers = action.payload.total_items;
    },
    [getUsers.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload.message;
    },
    // удаление пользователя
    [deleteUser.pending.type]: (state) => {
      state.isLoadingDelete = true;
    },
    [deleteUser.fulfilled.type]: (state, action) => {
      state.isLoadingDelete = false;
      state.isSuccessDelete = true;
      state.isErrorDelete = false;
      state.message = action.payload.message;
    },
    [deleteUser.rejected.type]: (state, action) => {
      state.isLoadingDelete = false;
      state.isSuccessDelete = false;
      state.isErrorDelete = true;
      state.message = action.payload.message;
    },

    // обновить статус пользователя
    [updateStatusUser.pending.type]: (state) => {
      state.isLoadingUpdateStatus = true;
    },
    [updateStatusUser.fulfilled.type]: (state, action) => {
      state.isLoadingUpdateStatus = false;
      state.isSuccessUpdateStatus = true;
      state.isErrorUpdateStatus = false;
      state.message = action.payload.message;
    },
    [updateStatusUser.rejected.type]: (state, action) => {
      state.isLoadingUpdateStatus = false;
      state.isSuccessUpdateStatus = false;
      state.isErrorUpdateStatus = true;
      state.message = action.payload.message;
    },

    // редактировать пользователя
    [editUser.pending.type]: (state) => {
      state.isLoadingEdit = true;
    },
    [editUser.fulfilled.type]: (state, action) => {
      state.isLoadingEdit = false;
      state.isSuccessEdit = true;
      state.isErrorEdit = false;
      state.message = action.payload.message;
    },
    [editUser.rejected.type]: (state, action) => {
      state.isLoadingEdit = false;
      state.isSuccessEdit = false;
      state.isErrorEdit = true;
      state.message = action.payload.message;
      state.editUserError = action.payload.form_error;
    },

    // войти в эмуляцию другого пользователя
    [logInToUserEmulation.pending.type]: (state) => {
      state.isLoadingEmulate = true;
    },
    [logInToUserEmulation.fulfilled.type]: (state, action) => {
      state.isLoadingEmulate = false;
      state.isSuccessEmulate = true;
      state.isErrorEmulate = false;
      state.emulateToken = action.payload.auth_other_user_token;
    },
    [logInToUserEmulation.rejected.type]: (state, action) => {
      state.isLoadingEmulate = false;
      state.isSuccessEmulate = false;
      state.isErrorEmulate = true;
      state.message = action.payload.message;
    },

    // выйти из эмуляции другого пользователя
    [logOutUserEmulation.pending.type]: (state) => {
      state.isLoadingLogoutEmulate = true;
    },
    [logOutUserEmulation.fulfilled.type]: (state, action) => {
      state.isLoadingLogoutEmulate = false;
      state.isErrorLogoutEmulate = false;
      state.isSuccessLogoutEmulate = true;
      state.message = action.payload.message;
    },
    [logOutUserEmulation.rejected.type]: (state, action) => {
      state.isLoadingLogoutEmulate = false;
      state.isErrorLogoutEmulate = true;
      state.isSuccessLogoutEmulate = false;
      state.message = action.payload.message;
    },

    // получить конкретного пользователя
    [getUser.pending.type]: (state) => {
      state.isLoadingGetUser = true;
    },
    [getUser.fulfilled.type]: (state, action) => {
      state.isSuccessGetUser = true;
      state.isLoadingGetUser = false;
      state.isErrorGetUser = false;
      state.message = action.payload.message;
      state.users = state.users.map((item) =>
        action.payload.id === item.id ? action.payload : item
      );
    },
    [getUser.rejected.type]: (state, action) => {
      state.isSuccessGetUser = false;
      state.isLoadingGetUser = false;
      state.isErrorGetUser = true;
      state.message = action.payload.message;
    },

    // Добавить пользователя в систему
    [addUser.pending.type]: (state) => {
      state.isLoadingAddUser = true;
    },
    [addUser.fulfilled.type]: (state, action) => {
      state.isSuccessAddUser = true;
      state.isLoadingAddUser = false;
      state.isErrorAddUser = false;
      state.message = action.payload.message;
    },
    [addUser.rejected.type]: (state, action) => {
      state.isSuccessAddUser = false;
      state.isLoadingAddUser = false;
      state.isErrorAddUser = true;
      state.message = action.payload.message;
      state.addUserError = action.payload.form_error;
    },

    // Подтвердить почту пользователя
    [confirmEmail.pending.type]: (state) => {
      state.isSuccessConfirmEmail = false;
      state.isLoadingConfirmEmail = true;
      state.isErrorConfirmEmail = false;
    },
    [confirmEmail.fulfilled.type]: (state, action) => {
      state.isSuccessConfirmEmail = true;
      state.isLoadingConfirmEmail = false;
      state.isErrorConfirmEmail = false;
      state.message = action.payload.message;
    },
    [confirmEmail.rejected.type]: (state, action) => {
      state.isSuccessConfirmEmail = false;
      state.isLoadingConfirmEmail = false;
      state.isErrorConfirmEmail = true;
      state.message = action.payload.message;
    },
  },
});

export const {
  resetUsersState,
  resetDeleteUserState,
  resetUpdateUserState,
  resetEditUserState,
  resetEmulateUserState,
  resetLogoutEmulateState,
  resetGetUserState,
  resetAddUserState,
  resetConfirmEmail,
} = usersSlice.actions;
export default usersSlice.reducer;
