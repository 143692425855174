import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './utils/i18n';

import MantineProviders from './utils/MantineProviders';
import App from './App';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
  <Provider store={store}>
    <MantineProviders>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MantineProviders>
  </Provider>
);
