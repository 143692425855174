import { FC } from 'react';
import { Global } from '@mantine/core';

import AppRouter from './routes/AppRouter';

const App: FC = () => {
  return (
    <>
      <Global
        styles={(theme) => ({
          body: {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#F0F1F2',
          },
        })}
      />
      <AppRouter />
    </>
  );
};

export default App;
