import { FC, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';

import { setUser } from '../store/entities/auth/authSlice';
import { useAppDispatch } from '../store/hooks';

import AdminRoutes from './AdminRoutes';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { adminRoutes, privateRoutes, publicRoutes, routes } from './routes-enums';

const AppRouter: FC = () => {
  const dispatch = useAppDispatch();
  const token = JSON.parse(localStorage.getItem('userToken') || '{}');

  useEffect(() => {
    dispatch(setUser(token));
  }, []);

  return (
    <Suspense
      fallback={
        <Center style={{ height: '100vh' }}>
          <Loader variant="dots" />
        </Center>
      }
    >
      <Routes>
        {publicRoutes.map((route) =>
          route.path ? (
            <Route
              path={route.path}
              element={<PublicRoutes>{route.component}</PublicRoutes>}
              key={route.path}
            />
          ) : null
        )}
        {privateRoutes.map((route) =>
          route.path ? (
            <Route
              path={route.path}
              element={<PrivateRoutes>{route.component}</PrivateRoutes>}
              key={route.path}
            />
          ) : null
        )}

        {adminRoutes.map((route) =>
          route.path ? (
            <Route
              path={route.path}
              element={<AdminRoutes>{route.component}</AdminRoutes>}
              key={route.path}
            />
          ) : null
        )}

        {routes.map((route) =>
          route.path ? <Route path={route.path} element={route.component} key={route.path} /> : null
        )}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
