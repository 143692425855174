import { FC } from 'react';
import { Group } from '@mantine/core';

import { IProviders } from '../../types/IProviders';

interface ProvidersProps {
  providers: IProviders[];
  iconSize?: number;
}

const Providers: FC<ProvidersProps> = ({ providers, iconSize = 30 }) => {
  return (
    <Group spacing={'xs'}>
      {providers.map((item) =>
        item.link ? (
          <a href={item.link} target="_blank" key={item.providerName}>
            <img alt="" src={item.logo} width={iconSize} />
          </a>
        ) : (
          <img alt="" src={item.logo} width={iconSize} />
        )
      )}
    </Group>
  );
};

export default Providers;
