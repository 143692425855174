export const dateToDDMMYYYY = (date: string) => {
  if (date) {
    const d = new Date(date);
    const dd = String(d.getDate()).padStart(2, '0');
    const mm = String(d.getMonth() + 1).padStart(2, '0');
    const yyyy = d.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
  } else {
    return date;
  }
};

export const htmlTextContent = (html: string) => {
  return html.replace(/<[^>]+>/g, '').trim();
};
