import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getHelpCards, getHelpCategories, getHelpTags
} from '../../action-creators/help';
import { HelpState } from '../../types/IHelp';

const initialState: HelpState = {
  isLoading: false,
  isError: false,
  isSuccess: false,

  isLoadingCategories: false,
  isErrorCategories: false,
  isSuccessCategories: false,

  isLoadingTags: false,
  isErrorTags: false,
  isSuccessTags: false,

  news: [],
  countNews: 0,
  categories: [],
  category: null,
  tags: [],
  tag: null
};

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    resetHelpState: () => initialState,
    setCategory(state, action: PayloadAction<string | null>) {
      state.category = action.payload;
    },  
    setTag(state, action: PayloadAction<string | null>) {
      state.tag = action.payload;
    },   
  },
  extraReducers: {
    // получение списка новостей
    [getHelpCards.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getHelpCards.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.news = action.payload.news;
      state.countNews = action.payload.total_items;
    },
    [getHelpCards.rejected.type]: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
    },

    // получение списка категорий для фильтра
    [getHelpCategories.pending.type]: (state) => {
      state.isLoadingCategories = true;
    },
    [getHelpCategories.fulfilled.type]: (state, action) => {
      state.isLoadingCategories = false;
      state.isSuccessCategories = true;
      state.isErrorCategories = false;
      state.categories = action.payload.categories;
    },
    [getHelpCategories.rejected.type]: (state) => {
      state.isLoadingCategories = false;
      state.isSuccessCategories = false;
      state.isErrorCategories = true;
    },
    
    // получение списка тегов для фильтра
    [getHelpTags.pending.type]: (state) => {
      state.isLoadingTags = true;
    },
    [getHelpTags.fulfilled.type]: (state, action) => {
      state.isLoadingTags = false;
      state.isSuccessTags = true;
      state.isErrorTags = false;
      state.tags = action.payload.tags;
    },
    [getHelpTags.rejected.type]: (state) => {
      state.isLoadingTags = false;
      state.isSuccessTags = false;
      state.isErrorTags = true;
    },
  },
});

export const {
  resetHelpState,
  setCategory,
  setTag
} = helpSlice.actions;
export default helpSlice.reducer;
