import { FC } from 'react';
import { Table as MTable, Text } from '@mantine/core';
import ContentContainer from 'src/ui/ContentContainer/ContentContainer';

import styles from './TableTariff.module.scss';

export interface IColumn {
  id: string;
  text: string;
}

interface ITable {
  tableInstance: {
    columns: Array<IColumn>;
    data: Array<any>;
  };
}

const TableTariff: FC<ITable> = ({ tableInstance }) => {
  const { columns, data } = tableInstance;

  return (
    <ContentContainer style={{ paddingTop: 0, paddingBottom: 5, marginTop: 10 }}>
      <div className={styles.tableWrapper}>
        <MTable highlightOnHover className={styles.table} verticalSpacing={16} horizontalSpacing="sm">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.id}>
                  <Text size={'sm'}>{column.text}</Text>
                </th>
              ))
              }
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => { }}>
                {columns.map((column) => (
                  <td key={column.id}>
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </MTable>
      </div>
    </ContentContainer>
  );
};

export default TableTariff;
